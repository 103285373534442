<template>
    <v-card class="pa-3">
        <v-card-title> Divergências de produtos </v-card-title>

        <v-card-text>
            <div class="d-flex align-center text-amber">
                <span>{{ products.length > 0 ? 'Produtos divergentes' : 'Tudo certo por aqui!' }}</span>
                <strong v-if="products.length > 0"> ({{ products.length }}) </strong>
            </div>
        </v-card-text>

        <div class="v-max">
            <v-alert variant="tonal" v-for="(product, index) in products" :key="index" class="mb-2" :icon="product.stock_situation.includes('Divergência') ? 'mdi-alert-octagon' : 'mdi-alert'" :type="product.stock_situation.includes('Divergência') ? 'error' : 'warning'">
                <v-row align="center">
                    <v-col cols="12" md="6">
                        <v-tooltip :text="product.name" location="top">
                            <template v-slot:activator="{ props }">
                                <h4 v-bind="props"> {{ maxChars(product.name, 24) }} </h4>
                            </template>
                        </v-tooltip>

                        <button @click="alert(product)" class="text-white text-decoration-none"> Ver detalhes </button>
                    </v-col>

                    <v-col cols="12" md="3" align-self="center">
                        <div class="text--primary font-weight-black d-flex align-start flex-column"> 
                            <v-tooltip text="Quantidade de produtos" location="top">
                                <template v-slot:activator="{ props }">
                                    <div v-bind="props" title="prod_qty" class="d-flex align-center"> 
                                        <v-icon color="white" size="16" start>mdi-warehouse</v-icon>
                                        <span> {{ product.prod_qty }} </span>
                                    </div>
                                </template>
                            </v-tooltip>

                            <v-tooltip text="Quantidade de vendas" location="top">
                                <template v-slot:activator="{ props }">
                                    <div v-bind="props" title="line_sale_qty" class="d-flex align-center"> 
                                        <v-icon color="white" size="16" start>mdi-cart</v-icon>
                                        <span> {{ product.line_sale_qty }} </span>
                                    </div>
                                </template>
                            </v-tooltip>

                            <v-tooltip text="Quantidade de compras" location="top">
                                <template v-slot:activator="{ props }">
                                    <div v-bind="props" title="line_stock_qty" class="d-flex align-center"> 
                                        <v-icon color="white" size="16" start>mdi-store</v-icon>
                                        <span> {{ product.line_stock_qty }} </span>
                                    </div>
                                </template>
                            </v-tooltip>

                            <v-tooltip text="Diferença" location="top">
                                <template v-slot:activator="{ props }">
                                    <div v-bind="props" title="total_difference" class="d-flex align-center"> 
                                        <v-icon color="white" size="16" start>mdi-alert</v-icon>
                                        <span> {{ product.total_difference }} </span>
                                    </div>
                                </template>
                            </v-tooltip>
                        </div>
                    </v-col>

                    <v-col cols="12" md="3">
                        <div class="d-flex justify-center align-end flex-column">
                            <div class="font-weight-bold text-white"> Situação </div>
                            <strong class="text--primary"> {{ product.stock_situation }} </strong>
                        </div>
                    </v-col>
                </v-row>
            </v-alert>
        </div>
    </v-card>
</template>

<script>
    import { constants } from '@/mixins/constants'; 
    export default { 
        mixins: [constants],

        data() {
            return {
                products: [],
            }
        },

        async mounted () {
            await this.fetchDivergences();
        },

        methods: {
            alert () {
                this.$notify({
                    icon: 'mdi-alert',
                    title: 'Produto em divergência',
                    html: `Favor conferir os documentos de compra e venda para esse produto ou entre em contato com o administrador do sistema`,
                    type: 'error',
                });
            },

            async fetchDivergences () {
                const [response, error] = await this.handleAsyncOperation(this.$axios.get(`/audit/products/quantities-divergent`));
                console.log({ response, error });
                if (!error && response.data.success) {
                    this.products = response.data.data;
                }
            },

            maxChars(text, size) {
                if (!text) return '';
                if (text.length > size) {
                    return text.substring(0, size) + '...';
                }
                return text;
            }
        }
    }
</script>

<style scoped>
.v-max {
    --card-size: calc(80px + 2rem);
    height: 100%;
    max-height: calc(var(--card-size) * 6);
    overflow: hidden;
    overflow-y: auto;
}
</style>