<template>
    <div>
        <UnitList />
    </div>
</template>

<script>
    import UnitList from '@/components/Base/panel/UnitList.vue';
    export default {
        name: 'page-units',
        components: { UnitList },
    }
</script>

<style lang="scss" scoped>

</style>