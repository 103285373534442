<template>
	<v-card class="pa-2">
		<v-card-title class="font-weight-bold"> {{ editedIndex == -1 ? "Adicionar" : "Editar" }} Produto </v-card-title>

		<v-form ref="form" class="px-4">
			<v-row>
			<v-col cols="12" lg="12" class="pb-0">
				<v-text-field
					variant="solo-filled"
					:rules="item.rules.name"
					v-model="modalData.name"
					label="Nome do Produto"
					required
					:disabled="saveLoading"
				></v-text-field>
			</v-col>
			<v-col cols="12" lg="4" class="pb-0">
				<v-text-field
					variant="solo-filled"
					:rules="[$minValueZero]"
					v-model.lazy="modalData.price_purchase"
					v-money="{decimalCount: 2, precision: 2, allowNegative: false, prefix: 'R$ ', thousands: '.', decimal: ','}"
					label="Preço de Compra"
					required
					:disabled="saveLoading"
					@keyup="calculateProfitMargin"
				></v-text-field>
			</v-col>
			<v-col cols="12" lg="4" class="pb-0">
				<v-text-field
					variant="solo-filled"
					:rules="[$minValueZero]"
					v-model.lazy="modalData.price_sale"
					v-money="{decimalCount: 2, precision: 2, allowNegative: false, prefix: 'R$ ', thousands: '.', decimal: ','}"
					label="Preço de Venda"
					required
					@keyup="calculateProfitMargin"
					:disabled="saveLoading"
				></v-text-field>
			</v-col>
			<v-col cols="12" lg="4" class="pb-0">
				<v-text-field
					:rules="[$minValueZero]"
					v-model.lazy="modalData.profit_margin"
					v-money="{decimalCount: 0, precision: 2, allowNegative: false, prefix: '', suffix: '%', thousands: '.', decimal: ','}"
					label="Margem"
					required
					variant="solo-filled"
					:disabled="saveLoading"
					@keyup="calculateSalePrice"
				></v-text-field>
			</v-col>
			<v-col cols="12" lg="4">
				<v-autocomplete
					:items="categories"
					item-title="name"
					item-value="id"
					v-model="modalData.category_id"
					label="Categoria"
					:disabled="saveLoading"
					:rules="item.rules.required"
					variant="solo-filled"
				></v-autocomplete>

				<v-btn
					color="primary"
					@click="categories_modal = true"
					text="Nova Categoria"
				></v-btn>

				<CategoryModal
					v-model="categories_modal"
					@close="categories_modal = false"
					@refresh="getCategories"
				/>
			</v-col>

			<v-col cols="12" lg="4" class="pb-0">
				<v-autocomplete
					:items="units"
					item-title="name"
					item-value="id"
					v-model="modalData.units_of_measurement_id"
					label="Unidade de Medida"
					:disabled="saveLoading"
					:rules="item.rules.required"
					variant="solo-filled"
				></v-autocomplete>
			</v-col>

			<v-col cols="12" lg="4" class="pb-0">
				<v-text-field
					v-model="modalData.quantity"
					variant="solo-filled"
					label="Quantidade"
					:disabled="saveLoading"
					readonly="readonly"
				></v-text-field>
			</v-col>

			<v-col cols="12">				
				<h2> Alerta de Estoque </h2>
			</v-col>
			
			<v-col cols="12" lg="4" class="pb-0">
				<v-text-field
					v-model="item.alerts.quantity"
					variant="solo-filled"
					label="Quantidade para alerta"
					:disabled="saveLoading"
				></v-text-field>
			</v-col>

			<v-col cols="12" lg="12" class="pb-0">
				<v-textarea
					variant="solo-filled"
					:rules="item.rules.description"
					v-model="modalData.description"
					label="Descrição"
					:disabled="saveLoading"
				></v-textarea>
			</v-col>
			</v-row>
		</v-form>

		<v-card-actions class="px-4">
			<v-spacer></v-spacer>
			<v-btn color="darken-1" text @click="clearItem(true)" :disabled="saveLoading"> Cancelar </v-btn>
			<v-btn color="green" @click="saveItem" :disabled="saveLoading"> Salvar </v-btn>
		</v-card-actions>

	</v-card>
</template>

<script>
import { constants } from '@/mixins/constants';
import CategoryModal from "@/components/Base/modal/CategoryModal.vue";
export default {
	name: "ProductModal",
	mixins: [constants],
	components: { CategoryModal },
	
	props: {
		product: {
			type: Object,
			required: true,
		},
		editedIndex: {
			type: Number,
			default: -1,
		},
	},
	data() {
		return {
			item: {
				alerts: { quantity: 100 },
				
				rules: {
					name: [
						(v) => !!v || "Nome é obrigatório",
						(v) => v.length <= 255 || "Nome deve ter no máximo 255 caracteres",
					],
					description: [
						(v) => v ? v.length <= 255 : true || "Descrição deve ter no máximo 255 caracteres",
					],
					required: [ (v) => !!v || "Este campo é obrigatório", ],
				},
			},

			saveLoading: false,

			categories: [],
			categories_modal: false,

			units: [],
		};
	},

	computed: {
		price_purchase () {
			return this.modalData.price_purchase;
		},
		price_sale () {
			return this.modalData.price_sale;
		},
		profit_margin () {
			return this.modalData.profit_margin;
		},

		modalData: {
			get() {
				return this.product;
			},
			set(val) {
				this.$emit("update:product", val);
			},
		},
	},

	async mounted() {
		await this.getCategories();
		await this.getUnits();

		// if (this.modalData.id) {
		// 	this.item.alerts = this.modalData.alerts ? JSON.parse(this.modalData.alerts) : { quantity: 100, product_id: null };
		// }

		if (this.modalData.id) {
			this.item.alerts = this.modalData.alerts.length ? this.modalData.alerts[0] : { quantity: 100 };
		}

		// console.log(this.modalData, this.item.alerts);
	},

	methods: {
		clearItem() {
			this.$emit("close");
		},
		async getCategories() {
			const [response, error] = await this.$handleAsync(this.$axios.get('/categories'));
			if (error) return console.log('Erro ao buscar categorias', error);
			this.categories = response.data.data;
		},

		async getUnits () {
			const [response, error] = await this.$handleAsync(this.$axios.get('/units'));
			if (error) return console.log('Erro ao buscar categorias', error);
			this.units = response.data.data;
		},

		async saveItem() {
			const { valid } = await this.$refs.form.validate();
			if (!valid) return;

			try {
				this.saveLoading = true;

				this.modalData.alert = JSON.stringify(this.item.alerts);
				delete this.modalData.alerts;
				
				this.modalData.price_sale = this.$stringToNumber(this.price_sale) || 0;
				this.modalData.price_purchase = this.$stringToNumber(this.price_purchase) || 0;
				this.modalData.profit_margin = this.$stringToNumber(this.profit_margin) || 0;
				
				const endpoint = this.modalData.id ? `/product/${this.modalData.id}` : "/product";
				const res = (await this.$axios[this.modalData.id ? "put" : "post"](endpoint, this.modalData)).data;

				if (res.success) {
					this.clearItem();
					
					this.$notify({
						title: "Sucesso",
						text: `Produto ${this.modalData.id ? "editado" : "cadastrado"} com sucesso`,
						icon: "success",
					});
				}

				this.$emit("refresh");
			} catch (error) {
				console.log(error);
			}
			this.saveLoading = false;
		},

		calculateSalePrice() {
			const price_purchase = this.$stringToNumber(this.price_purchase) || 0;
			const profit_margin = this.$stringToNumber(this.profit_margin) || 0;

			const url = `/products/simulate/${this.modalData.id || 0}?price_purchase=${price_purchase}&profit_margin=${profit_margin}`;
			if (price_purchase > 0 && profit_margin > 0) {

				this.$handleAsync(this.$axios.get(url)).then(([response, error]) => {
					if (error) return console.log(error);

					if (response.data.success) {
						const { price_sales } = response.data.data;
						this.modalData.price_sale = (price_sales).toFixed(2);
					}
				});
			}
			
		},
		calculateProfitMargin() {
			const price_sale = this.$stringToNumber(this.price_sale) || 0;
			const price_purchase = this.$stringToNumber(this.price_purchase) || 0;

			if (price_sale > 0 && price_purchase > 0) {
				const url = `/products/simulate/${this.modalData.id || 0}?price_sales=${price_sale}&price_purchase=${price_purchase}`;

				this.$handleAsync(this.$axios.get(url)).then(([response, error]) => {
					if (error) return console.log(error);

					if (response.data.success) {
						const { profit_margin } = response.data.data;
						this.modalData.profit_margin = (profit_margin).toFixed(2);
					}
				});
			}
		},
	},
};
</script>

<style lang="scss" scoped></style>
