<template>
  <div>
    <ListCRUD :headers="list.headers" :items="list.items" @action:edit="editItem" @action:delete="deleteItem">
      <template v-slot:header>
        <v-toolbar color="transparent" rounded="12px">
          <v-toolbar-title> Lista de fornecedores </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn color="primary" @click="openModal" class="mb-2"> Novo Fornecedor </v-btn>

          <v-dialog v-model="dialog" max-width="clamp(50%, 500px, 100%)">
            <v-card class="pa-2">
              <v-card-title class="font-weight-bold"> {{ editedIndex == -1 ? "Adicionar" : "Editar" }} Fornecedor </v-card-title>

              <company-form getCPNJ v-model="editedItem" :loading="list.loading" @on:submit="saveItem" @on:cancel="dialog = !dialog" close></company-form>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:[`cnpj`]="{ item }">
        <span> {{ $formatNumber('cnpj', item.raw.cnpj) }} </span>
      </template>
      
      <template v-slot:[`phone`]="{ item }">
        <v-tooltip location="top" :text="`Ligar para o fornecedor`">
          <template v-slot:activator="{ props }">
            <a v-bind="props" :href="`tel:55${$toNumeric(item.raw.phone)}`" class="text-decoration-none"> 
              <v-icon start color="white" size="16"> mdi-phone </v-icon> 
              
              <span class="text-white"> {{ $formatNumber('phone', $toNumeric(item.raw.phone)) }} </span>
            </a>
          </template>
        </v-tooltip>
      </template>

      <template v-slot:[`email`]="{ item }">
        <v-tooltip location="top" :text="item.raw.email">
          <template v-slot:activator="{ props }">
            <a :href="`mailto:${item.raw.email}`"> <v-icon v-bind="props" color="white"> mdi-email </v-icon> </a>
          </template>
        </v-tooltip>
      </template>
    </ListCRUD>
  </div>
</template>

<script>
  import Swal from 'sweetalert2';
  import { constants } from '@/mixins/constants';
  import ListCRUD from '@/components/Base/ListCRUD.vue';
  import CompanyForm from '@/components/CompanyForm.vue';

  export default {
    mixins: [constants],
    components: { ListCRUD, CompanyForm },    
    data() {
      return {
        list: {
          items: [],
          search: '',
          loading: false,
          headers: [
            { title: 'ID', key: 'id' },
            { title: 'Nome', key: 'name' },
            { title: 'CPF/CNPJ', key: 'cnpj' },
            { title: 'Telefone', key: 'phone' },
            { title: 'E-mail', key: 'email' },
            { title: 'Ações', key: 'actions', sortable: false },
          ],
        },

        dialog: false,

        editedIndex: -1,
        editedItem: {},
      }
    },

    mounted () {
      this.fetchItems();
      const creating = Object.hasOwnProperty.call(this.$route.query, 'creating');
      if (creating) this.openModal();
    },

    watch: {
      dialog (val) {
        val || this.closeModal();
      },

      '$route.query' (val) {
        const creating = Object.hasOwnProperty.call(val, 'creating');
        if (creating) this.openModal();
      }
    },

    methods: {
      openModal() {
        this.dialog = true;
      },

      closeModal() {
        this.dialog = false;
        this.editedIndex = -1;
        this.editedItem = {};

        const creating = Object.hasOwnProperty.call(this.$route.query, 'creating');
        if (creating) this.$router.replace({ query: null });
      },

      editItem (item) {        
        Object.assign(this.editedItem, item);
        this.editedIndex = this.list.items.indexOf(item);
        if (this.editedIndex > -1) this.openModal();
      },

      async deleteItem(item) {
        Swal.fire({
          title: 'Tem certeza?',
          text: "Você não poderá reverter isso!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim, deletar!',
          cancelButtonText: 'Não, cancelar!',
          reverseButtons: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            const [response, error] = await this.handleAsyncOperation(this.$axios.delete(`/suppliers/${item.id}`));

            if (error) {
              const message = error?.response?.data?.error?.message || 'Erro ao deletar fornecedor!';

              return this.$notify({
                title: 'Atenção!',
                text: message,
                icon: 'error',
              })
            }

            this.$notify({
              title: 'Sucesso!',
              text: response.data.data || 'Fornecedor deletado com sucesso!',
              icon: 'success',
            })

            await this.fetchItems();
          }
        })
      },

      async saveItem () {
        this.list.loading = true;

        const url = this.editedItem.id ? `/suppliers/${this.editedItem.id}` : '/suppliers';
        const method = this.editedItem.id ? 'put' : 'post';

        // fix for cep
        const form = { ...this.editedItem };
        form.cnpj = this.$toNumeric(this.editedItem.cnpj)
        form.phone = this.$toNumeric(this.editedItem.phone)
        form.mobile = this.$toNumeric(this.editedItem.mobile)
        form.address_zipcode = this.$toNumeric(this.editedItem.address_zipcode)
        
        const [response, error] = await this.handleAsyncOperation(this.$axios[method](url, form));
        if (!error && response.data.success) {
          this.dialog = false;
          await this.fetchItems();

          this.$notify({
            title: 'Sucesso!',
            text: 'Ação realizada com sucesso!',
            icon: 'success',
          })
        } else {
          this.$notify({
            title: 'Erro!',
            text: 'Erro ao cadastrar fornecedor!',
            icon: 'error',
          })
        }

        this.list.loading = false;
      },

      async fetchItems() {
        this.list.loading = true;
        
        const [response, error] = await this.handleAsyncOperation(this.$axios.get("/suppliers"));
        const { data: itemData } = response;
        if (!error && itemData.success) {
          this.list.items = itemData.data;
        }

        this.list.loading = false;
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>