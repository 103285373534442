import axios from 'axios';
// import router from '../router';
import Swal from 'sweetalert2';

const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL
});

const authInterceptor = (config) => {
    config.headers['Authorization'] = `Bearer ${getToken()}`;
    return config;
}

const errorInterceptor = error => {
    if (!error.response) {
        return Promise.reject(error);
    }
    
    if (error.response.status === 401) {
        console.error('** Unauthorized **');  
        localStorage.removeItem('_token');

        Swal.fire({
            icon: 'error',
            title: 'Sua sessão expirou',
            text: 'Faça login novamente para continuar',
            confirmButtonText: 'Ok',
            allowOutsideClick: false
        }).then(() => {
            window.location.href = '/login';
        });
    }
    return Promise.reject(error);
}

const getToken = () => {
    return localStorage.getItem('_token') || '';
};

instance.interceptors.request.use(authInterceptor);
instance.interceptors.response.use(response => response, errorInterceptor);

export default {
    $https: instance,
    install: (app) => {
        app.config.globalProperties.$axios = instance;
    }
}