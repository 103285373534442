<template>
  <div>
    <v-text-field
      v-model="selectedDate"
      type="datetime-local"
      label="Escolha a data de emissão"
      density="compact"
      variant="solo-filled"
    ></v-text-field>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        menu: false,
        selectedDate: null,
      }
    },

    mounted () {
      this.setInitialDate();
    },

    methods: {
      updateDate() {
        this.$emit('input', this.selectedDate); // Emita o evento 'input' com a data selecionada
      },

      setInitialDate() {
        const timeLocale = new Date().toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' }); 
        const [date, time] = timeLocale.split(', ');
        const [day, month, year] = date.split('/');
        const [hour, minute, second] = time.split(':');
        this.selectedDate = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>