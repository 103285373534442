<template>
    <div>
        <v-overlay
            scrim="#000"
            :model-value="sales.loading"
            persistent
            class="align-center justify-center"
        >
            <v-progress-circular
                color="primary"
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>

        <h2 class="mb-4">Vendas</h2>

        <v-card class="mb-4" rounded="lg">
            <v-card-title class="text-amber font-weight-bold"> Filtros </v-card-title>

            <v-card-text> 
                <v-row class="mb-5">
                    <!-- <v-col cols="12" md="">
                        <v-text-field
                            v-model.number="itemsPerPage"
                            type="number"
                            label="Itens por página"
                            variant="solo-filled"
                            hide-details=""
                        ></v-text-field>
                    </v-col> -->

                    <v-col cols="12" md="">
                        <v-text-field
                        v-model="initialDate"
                        type="date"
                        label="Data Inicial"
                        variant="solo-filled"
                        hide-details=""
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="">
                        <v-text-field
                        v-model="finalDate"
                        type="date"
                        label="Data Final"
                        variant="solo-filled"
                        hide-details=""
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="">
                        <v-autocomplete
                            v-model="client"
                            :items="clients"
                            :custom-filter="customFilter"
                            label="Localizar por cliente"
                            item-title="name"
                            item-value="[id, name, register]"
                            outlined
                            dense
                            clearable
                            return-object
                            variant="solo-filled"
                            class="mb-2"
                            >
                            <template v-slot:item="{ props, item }">
                                <v-list-item
                                    v-bind="props"
                                    three-line
                                >
                                    <v-list-item-subtitle>
                                        {{ item.raw.register_type == 2 ? 'CNPJ' : 'CPF' }}: {{ formatNumber(item.raw.register, item.raw.register_type == 2 ? 'cnpj' : 'cpf') }}
                                    </v-list-item-subtitle>

                                    <v-list-item-subtitle>
                                        Telefone: {{ formatNumber(item.raw.phone, 'phone') }}
                                    </v-list-item-subtitle>
                                </v-list-item>
                            </template>

                            </v-autocomplete>
                    </v-col>

                    <v-col cols="12" md="">
                        <v-autocomplete
                            v-model="productsIds"
                            :items="products"
                            item-title="name"
                            item-value="id"
                            variant="solo-filled"
                            label="Buscar por produto(s)"
                            multiple
                            chips
                            clearable
                            :closable-chips="true"
                        ></v-autocomplete>
                    </v-col>

                    <v-col cols="12">
                        <v-checkbox
                            v-model="withPendingPayments"
                            label="Pagamentos em aberto"
                            hide-details
                        ></v-checkbox>
                    </v-col>
                </v-row>
                
                <v-divider></v-divider>
            </v-card-text>
            
            <v-card-actions class="pa-5">
                <v-btn min-height="52" class="px-4 px-md-10" variant="outlined" @click="fetchPage(this.currentPage)"> Filtrar </v-btn> 

                <v-spacer></v-spacer>

                <v-btn min-height="52" class="px-4 px-md-10" variant="outlined" color="primary" to="/sale/new"> Nova Venda </v-btn>
            </v-card-actions>
        </v-card>

        <ListCRUD 
            :showDelete="false" 
            :headers="sales.headers" 
            :items="sales.items" 
            @action:edit="showDetails"
        >   
            <template v-slot:[`date`]="{ item }">
                {{ formatDate(item.raw.date) }}
            </template>

            <template v-slot:[`paid`]="{ item }">
                <v-chip color="success" v-if="item.raw.paid"> Pago </v-chip>
                <v-chip color="warning" v-else> Pendente </v-chip>
            </template>

            <template v-slot:[`total`]="{ item }">
                {{ formatCurrency(item.raw.total) }}
            </template>
        </ListCRUD>

        <nav aria-label="Page navigation" class="my-5 text-center">
            <v-btn @click="fetchPage(currentPage - 1)" :disabled="currentPage === 1" small > Anterior </v-btn>
            <span class="mx-5">{{ currentPage }} de {{ totalPages }}</span>
            <v-btn @click="fetchPage(currentPage + 1)" :disabled="currentPage === totalPages" small > Próxima </v-btn>
        </nav>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { constants } from '@/mixins/constants';
    import ListCRUD from '@/components/Base/ListCRUD.vue';
    export default {
        components: { ListCRUD },
        mixins: [constants],
        name: 'page-unit-list',
        data() {
            return {
                sales: {
                    headers: [
                        { title: 'Número da Venda', key: 'number' },
                        { title: "Pago", key: 'paid' },
                        { title: "Estado", key: 'state' },
                        { title: 'Cliente', key: 'client_name' },
                        { title: 'Data da venda', key: 'date' },
                        { title: 'Total', key: 'total' },
                        { title: 'Ações', key: 'actions' },
                    ],
                    items: [],
                    loading: true,
                },

                page: 1,
                itemsPerPage: 10,

                client: null,
                productsIds: [],

                initialDate: null,
                finalDate: null,

                withPendingPayments: false,
            }
        },

        computed: {
            ...mapState(['products', 'clients']),

            totalPages() {
                return this.sales.last_page;
            },
            currentPage() {
                return this.sales.current_page;
            },
        },

        async mounted() {
            const date = new Date();
            const month = date.getMonth() + 1;

            this.initialDate = `${date.getFullYear()}-${month.toString().padStart(2, '0')}-01`;
            this.finalDate = `${date.getFullYear()}-${month.toString().padStart(2, '0')}-${new Date(date.getFullYear(), month, 0).getDate()}`;

            await Promise.all([
                this.$store.dispatch('getClients'),
                this.$store.dispatch('getProducts'),
                this.$store.dispatch('getDocumentTypes'),
                this.fetchPage(1),
            ])
        },

        methods: {
            customFilter (itemTitle, queryText, item) {
                const clientName = item.raw.name.toLowerCase()
                const cpfNumber = this.$stringToNumber(item.raw.register.toString()).toString()
                const phone = item.raw?.phone != null ? this.$stringToNumber(item.raw.phone.toString()).toString() : '';
                const searchText = queryText.toLowerCase()
                return clientName.indexOf(searchText) > -1 || cpfNumber.indexOf(searchText) > -1 || phone.indexOf(searchText) > -1
            },

            // TODO: remover este método e usar o $formatNumber global quando estiver disponível no projeto
            formatNumber(number, type = "phone") {
                if (!number) return;
                number = number.toString();

                const formats = {
                cep: `${number.replace(/(\d{5})(\d{3})/, "$1-$2")}`,
                phone: `${number.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3")}`,
                cpf: `${number.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")}`,
                cnpj: `${number.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")}`,
                }

                return formats[type] || number;
            },

            showDetails (that) {
                this.$router.push(`/sale/${that.id}`);
            },
            async getSalesNotes () {
                const [response, error] = await this.$handleAsync(this.$axios.get('/sales'));
                if (!error && response.data.success) {
                    this.sales.items = response.data.data.data;
                    this.sales.loading = false;          
                }
            },          
            
            async fetchPage(page) {
                this.sales.loading = true;
                this.page = page || this.currentPage || 1;
                const productsMap = this.productsIds.map(product => product);

                const params = {
                    page: this.page,
                    per_page: this.itemsPerPage,
                    initial_date: this.initialDate,
                    final_date: this.finalDate,
                    with_pending_payments: this.withPendingPayments ? 1 : 0,
                    ...(this.client ? { register: this.client.register } : null),
                    ...(productsMap ? { products: productsMap } : null),
                };

                const [response, error] = await this.$handleAsync(this.$axios.get('/sales', { params }));  
                
                if (!error) {
                    const salesOptions = response.data.data;
                    const { data, last_page, current_page } = salesOptions;

                    this.sales.items = data;
                    this.sales.current_page = current_page;
                    this.sales.last_page = last_page;
                }

                this.sales.loading = false;
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>