import axios from 'axios';
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

export default {
    login(email, password) {
        return axios.post('/login', { email, password });
    },

    logout() {
        return axios.post('/logout');
    },
}