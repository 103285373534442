<template>
    <v-row>
        <v-col cols="12" class="mx-4">
            <h2> Informações da empresa </h2>
        </v-col>
        
        <v-col cols="12">
            <company-form v-model="form" :loading="loading" @on:submit="save"></company-form>
        </v-col>
    </v-row>
</template>

<script>
    import { mapState } from 'vuex'
    import CompanyForm from '@/components/CompanyForm.vue'
    import { constants } from '@/mixins/constants';
    export default {
        mixins: [constants],
        components: { CompanyForm },
        data() {
            return {
                loading: true,
                creating: false,

                form: {},
            }
        },

        async mounted () {
            if (!this.isLogged) return this.$router.push('/login')
            await this.getCompanyById()
        },

        watch: {
            async user () {
                await this.getCompanyById()
            },
        },

        computed: {
            ...mapState(['isLogged', 'user']),
        },

        methods: {
            async getCompanyById () {
                const [response, error] = await this.$handleAsync(this.$axios.get(`/company/${this.user.id}`));
                
                if (error) {
                    this.creating = true
                    this.loading = false;
                    return;
                }

                Object.assign(this.form, response.data.data)
                this.loading = false
            },

            async save() {
                this.loading = true
                const endpoint = this.creating ? '/company' : `/company/${this.user.id}`;
                const method = this.creating ? 'post' : 'put';

                const form = { ...this.form }

                form.cnpj = this.$toNumeric(this.form.cnpj)
                form.phone = this.$toNumeric(this.form.phone)
                form.mobile = this.$toNumeric(this.form.mobile)
                form.address_zipcode = this.$toNumeric(this.form.address_zipcode)

                const [response, error] = await this.handleAsyncOperation(this.$axios[method](endpoint, form));
                if (!error && response.data.success) {
                    this.$notify({
                        title: 'Sucesso!',
                        text: 'Ação realizada com sucesso!',
                        icon: 'success',
                    })
                } else {
                    this.$notify({
                        title: 'Erro!',
                        text: 'Erro ao cadastrar fornecedor!',
                        icon: 'error',
                    })
                }
            
                this.loading = false
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>