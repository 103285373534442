<template>
    <div>
        <v-overlay
            scrim="#000"
            :model-value="stocks.loading"
            persistent
            class="align-center justify-center"
        >
            <v-progress-circular
                color="primary"
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>

        <h2 class="mb-4">Entrada de Estoque</h2>

        <v-card class="mb-4" rounded="lg">
            <v-card-title class="text-amber font-weight-bold"> Filtros </v-card-title>

            <v-card-text> 
                <v-row class="mb-5">
                    <!-- <v-col cols="12" md="">
                        <v-text-field
                            v-model.number="itemsPerPage"
                            type="number"
                            label="Itens por página"
                            variant="solo-filled"
                            hide-details=""
                        ></v-text-field>
                    </v-col> -->

                    <v-col cols="12" md="">
                        <v-text-field
                        v-model="initialDate"
                        type="date"
                        label="Data Inicial"
                        variant="solo-filled"
                        hide-details=""
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="">
                        <v-text-field
                        v-model="finalDate"
                        type="date"
                        label="Data Final"
                        variant="solo-filled"
                        hide-details=""
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="">
                        <v-autocomplete
                            v-model="supplier"
                            :items="suppliers"
                            item-title="name"
                            item-value="id"
                            variant="solo-filled"
                            label="Buscar por fornecedor"
                            hide-details
                        ></v-autocomplete>
                    </v-col>

                    <v-col cols="12" md="">
                        <v-autocomplete
                            v-model="productsIds"
                            :items="products"
                            item-title="name"
                            item-value="id"
                            variant="solo-filled"
                            label="Buscar por produto(s)"
                            multiple
                            chips
                            clearable
                            :closable-chips="true"
                        ></v-autocomplete>
                    </v-col>
                </v-row>
                
                <v-divider></v-divider>
            </v-card-text>
            
            <v-card-actions class="pa-5">
                <v-btn min-height="52" class="px-4 px-md-10" variant="outlined" @click="fetchPage(this.currentPage)"> Filtrar </v-btn> 

                <v-spacer></v-spacer>

                <v-btn min-height="52" class="px-4 px-md-10" variant="outlined" color="primary" to="/stock/new"> Nova Entrada </v-btn>
            </v-card-actions>
        </v-card>

        <ListCRUD 
            :showDelete="false" 
            :footer="false"
            :headers="stocks.headers" 
            :items="stocks.items" 
            @action:edit="editStock"
        >
            <template v-slot:[`document_type_id`]="{ item }">
                <span>{{ getDocumentTypeName(item.raw.document_type_id) }}</span>
            </template>

            <template v-slot:[`warehouse_id`]="{ item }">
                <span>{{ getWarehouseName(item.raw.warehouse_id) }}</span>
            </template>

            <template v-slot:[`document_number`]="{ item }">
                <strong>#{{ $leftPad(item.raw.document_number, 6) }}</strong>
            </template>
        </ListCRUD>

        <nav aria-label="Page navigation" class="my-5 text-center">
            <v-btn @click="fetchPage(currentPage - 1)" :disabled="currentPage === 1" small > Anterior </v-btn>
            <span class="mx-5">{{ currentPage }} de {{ totalPages }}</span>
            <v-btn @click="fetchPage(currentPage + 1)" :disabled="currentPage === totalPages" small > Próxima </v-btn>
        </nav>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import ListCRUD from '../../components/Base/ListCRUD.vue'
    export default {
        components: { ListCRUD },
        data() {
            return {
                stocks: {
                    items: [],
                    search: '',
                    loading: true,
                    headers: [
                        // { title: 'ID', key: 'id' },
                        { title: "Tipo de documento", key: 'document_type_id' },
                        { title: 'Número do documento', key: 'document_number' },
                        // { title: 'Fornecedor', key: 'provider_id' },
                        { title: 'Armazém', key: 'warehouse_id' },

                        { title: 'Estado', key: 'state' },
                        { title: 'Ações', key: 'actions', sortable: false },
                    ],
                },

                // TODO: get from api
                warehouses: [
                    { id: 1, name: 'Sede' },
                    { id: 2, name: 'Filial' },
                ],

                documentTypes: [],

                page: 1,
                itemsPerPage: 10,

                initialDate: null,
                finalDate: null,

                supplier: null,
                productsIds: [],
            }
        },

        computed: {
            ...mapState(['products', 'suppliers', 'stock_types']),
            totalPages() {
                return this.stocks.last_page;
            },
            currentPage() {
                return this.stocks.current_page;
            },
        },

        async mounted() {
            const date = new Date();
            const month = date.getMonth() + 1;

            this.initialDate = `${date.getFullYear()}-${month.toString().padStart(2, '0')}-01`;
            this.finalDate = `${date.getFullYear()}-${month.toString().padStart(2, '0')}-${new Date(date.getFullYear(), month, 0).getDate()}`;

            await Promise.all([
                this.$store.dispatch('getProducts'),
                this.$store.dispatch('getSuppliers'),
                this.$store.dispatch('getDocumentTypes'),
                this.fetchPage(1),
            ])
        },

        methods: {
            getWarehouseName(id) {
                return this.warehouses.find(warehouse => warehouse.id == id).name
            },

            getDocumentTypeName(id) {
                return this.stock_types.find(documentType => documentType.id == id)?.name || 'Não encontrado';
            },
            
            editStock (item) {
                this.$router.push(`/stock/${item.id}`);
            },

            async getItemsStock() {
                const [response, error] = await this.$handleAsync(this.$axios.get('/stocks'));
                if (!error && response.data.success) {
                    this.stocks.items = response.data.data.data;
                }
            },
            async fetchPage(page) {
                this.stocks.loading = true;
                this.page = page || this.currentPage || 1;

                const productsMap = this.productsIds.map(product => product);

                const params = {
                    page: this.page,
                    per_page: this.itemsPerPage,
                    initial_date: this.initialDate,
                    final_date: this.finalDate,
                    ...(this.supplier ? { supplier_id: this.supplier } : null),
                    ...(productsMap ? { products: productsMap } : null),
                };

                const [response, error] = await this.$handleAsync(this.$axios.get('/stocks', { params }));  
                
                if (!error) {
                    const stockOptions = response.data.data;
                    const { data, last_page, current_page } = stockOptions;

                    this.stocks.items = data;
                    this.stocks.current_page = current_page;
                    this.stocks.last_page = last_page;
                }

                this.stocks.loading = false;
            },
        },
                   
    }
</script>

<style lang="scss" scoped>

</style>