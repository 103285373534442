<template>
  <div>
    <h1 class="mb-2">Gestão de Custos</h1>

    <v-row class="mb-5">
      <v-col cols="12" lg="4">
        <v-text-field
          v-model="initialDate"
          type="date"
          label="Initial Date"
          variant="solo-filled"
          hide-details=""
        ></v-text-field>
      </v-col>

      <v-col cols="12" lg="4">
        <v-text-field
          v-model="finalDate"
          type="date"
          label="Final Date"
          variant="solo-filled"
          hide-details=""
        ></v-text-field>
      </v-col>

      <v-col cols="12" lg="2">
        <v-btn class="h-100 w-100" @click="fetchPage(this.currentPage)">
          Filtrar
        </v-btn>
      </v-col>

      <v-col cols="12" lg="2">
        <v-btn color="primary" class="h-100 w-100" @click="downloadReport">
          Exportar Planilha
        </v-btn>
      </v-col>
    </v-row>

    <v-progress-circular
      model-value="20"
      v-if="costs.length"
    ></v-progress-circular>

    <ListCRUD :headers="headers" :items="costs.data" @action:edit="editItem" @action:delete="deleteItem" @refresh="fetchPage">
      <template v-slot:header>
        <v-toolbar color="transparent" rounded="12px">
          <v-toolbar-title> Lista de despesas </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn color="primary" @click="dialog = !dialog" class="mb-2"> Adicionar uma despesa </v-btn>

          <v-dialog v-model="dialog" max-width="clamp(50%, 500px, 100%)">
            <cost-modal :item="editedItem" :editedIndex="editedIndex" :submitting="submitting" @close="close" @save="save"></cost-modal>
          </v-dialog>
        </v-toolbar>
      </template>
    </ListCRUD>

    <nav aria-label="Page navigation" class="my-5 text-center">
      <v-btn @click="fetchPage(currentPage - 1)" :disabled="currentPage === 1" small > Anterior </v-btn>
      <span class="mx-5">{{ currentPage }} de {{ totalPages }}</span>
      <v-btn @click="fetchPage(currentPage + 1)" :disabled="currentPage === totalPages" small > Próxima </v-btn>
    </nav>
  </div>
</template>

<script>
import ListCRUD from '@/components/Base/ListCRUD.vue';
import CostModal from '@/components/Base/modal/CostModal.vue';
import Swal from 'sweetalert2';
export default {
  name: "page-costs",
  components: {ListCRUD, CostModal},

  data() {
    return {
      costs: [],
      value: 0,
      submitting: false,

      page: 1,
      itemsPerPage: 5,

      initialDate: null,
      finalDate: null,

      dialog: false,

      editedIndex: -1,
      editedItem: {
        name: "",
        description: "",
        cost: 0,
        date: null,
        type: 1,
        frequency: 1,
      },

      defaultItem: {
        name: "",
        description: "",
        cost: 0,
        type: 1,
        date: null,
        frequency: 1,
      },

      frequencies: [
        { text: "Mensal", value: 1 },
        { text: "Bimestral", value: 2 },
        { text: "Trimestral", value: 3 },
        { text: "Semestral", value: 4 },
        { text: "Anual", value: 5 },
      ],

      types: [
        { text: "Despesa", value: 1 },
        { text: "Receita", value: 2 },
      ],

      headers: [
        // {
        //   title: "ID",
        //   align: "start",
        //   sortable: false,
        //   key: "id",
        // },
        { title: "Nome da despesa", key: "name" },
        { title: "Descrição", key: "description" },
        { title: "Valor", key: "cost" },
        { title: "Vencimento", key: "date" },
        { title: "Tipo", key: "type" },
        { title: "Frequência", key: "frequency" },
        { title: "", key: "actions", sortable: false },
      ],

      rules: {
        required: (value) => !!value || "Campo obrigatório",
      },
    };
  },

  provide() {
    return {
      rules: this.rules,
      frequencies: this.frequencies,
    };
  },

  computed: {
    totalPages() {
      return this.costs.last_page;
    },
    lastPage() {
      return this.costs.last_page;
    },
    currentPage() {
      return this.costs.current_page;
    },
  },

  mounted() {
    const date = new Date();
    const month = date.getMonth() + 1;

    this.initialDate = `${date.getFullYear()}-${month.toString().padStart(2, '0')}-01`;
    this.finalDate = `${date.getFullYear()}-${month.toString().padStart(2, '0')}-${new Date(date.getFullYear(), month, 0).getDate()}`;

    this.fetchPage(1);
  },
  methods: {
    async downloadReport () {
      const params = {
        initial_date: this.initialDate,
        final_date: this.finalDate,
      };
      
      const endpoint = `/costs/export/csv`;

      try {
        const response = (await this.$axios.get(endpoint, { params })).data;
        const file = new Blob([response.data.content], { type: "text/csv" });

        const file_name = response.data.file_name;

        // create an url to download the file and click on it
        const url = window.URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file_name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error(error);
      }
    },

    close() {
      this.dialog = false;
      
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    async save() {
      
      try {
        this.submitting = true;

        const { id } = this.editedItem;
        this.editedItem.cost = this.$stringToNumber(this.editedItem.cost);

        const endpoint = id ? `/costs/${id}` : "/costs";
        const method = id ? "put" : "post";
        const response = await this.$axios[method](endpoint, this.editedItem);

        if (response.status !== 200) {
          console.error("Error saving:", response);
        }

        this.close();
        this.fetchPage();

        this.$notify({
          title: "Sucesso",
          text: "Ação realizada com sucesso",
          icon: "success",
        });

      } catch (error) {
        console.error("Error saving:", error);
      } finally {
        this.submitting = false;
      }
    },

    async fetchPage(page) {
      this.page = page || this.currentPage || 1;

      try {
        const params = {
          page: this.page,
          items_per_page: this.itemsPerPage,
          initial_date: this.initialDate,
          final_date: this.finalDate,
        };

        const paramsString = new URLSearchParams(params);
        const endpoint = `/costs?${paramsString}`;

        const response = await this.$axios.get(endpoint).catch((error) => {
          console.log(error);
        });

        if (response.status !== 200) {
          throw new Error("Error fetching page");
        }

        const { data } = response.data;
        this.costs = data;
      } catch (error) {
        console.error("Error fetching page:", error);
      }
    },

    editItem (item) {
      Object.assign(this.editedItem, item);

      this.dialog = true;
      this.editedIndex = this.costs.data.indexOf(item);
    },

    deleteItem (item) {
      const { id } = item;
      const endpoint = `/costs/${id}`;

      Swal.fire({
        title: 'Tem certeza?',
        text: "Você não poderá reverter isso!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, deletar!',
        cancelButtonText: 'Não, cancelar!',
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$axios.delete(endpoint);
            await this.fetchPage();
          } catch (error) {
            console.log(error);
          }

          this.$notify({
            title: 'Sucesso!',
            text: 'Ação realizada com sucesso!',
            icon: 'success',
          })
        }
      })

      console.error("deleteItem", item);
    },
  },
};
</script>

<style lang="scss" scoped></style>
