<template>
  <div>
    <v-autocomplete
      class="mb-10"
      v-model="product"
      :items="productsFiltered"
      :filter="customFilter"
      label="Localizar produto"
      item-title="name"
      item-value="[id, name, register, description, quantity, price_sale]"
      item-disabled="quantity === 0"
      outlined
      variant="solo-filled"
      density="compact"
      clearable
      return-object
    > 
      <template v-slot:item="{ props, item }">
        <v-list-item
          v-bind="props"
          class="mb-2 pb-4"
          :title="item.raw.description"
          :disabled="item.raw.disabled"
        >
          <template v-slot:title>
            <v-tooltip location="top start">
              <template v-slot:activator="{ props }">
                <v-list-item-title v-bind="props"> {{ $maxChars(item.raw.name, 20) }} </v-list-item-title>
              </template>

              <span> Nome: <b>{{ item.raw.name }} </b> <br /> Descrição <b> {{ item.raw.description || 'Nenhuma descrição disponível' }} </b> </span>
            </v-tooltip>
          </template>

          <template v-slot:[`subtitle`]>
            <strong>
              {{ item?.raw?.quantity }}
              {{ item?.raw?.units?.symbol }} disponíveis
            </strong>
          </template>
        </v-list-item>
      </template>
    </v-autocomplete>

    <v-table>
      <thead>
        <tr>
          <th>Quantidade</th>
          <th>Unidade de medida</th>
          <th>Nome do produto</th>
          <th>PR. Unitário</th>
          <th class="text-end">Total</th>
          <th></th>
        </tr>
      </thead>

      <tbody v-if="note.products.length > 0">
        <tr v-for="(product, index) in note.products" :key="index">
          <td style="width: 200px"> 
            <v-text-field 
              class="mt-4"
              v-model.number="product.amount"
              type="number" 
              outlined
              variant="solo-filled"
              density="compact"
              single-line=""
              :min="1"
              :max="product.quantity"
              :disabled="disabled"
              @change="updateTotal(product)"
              :rules="[v => !!v || 'Campo obrigatório',
                v => v <= product.quantity || 'Quantidade maior que o estoque',
                v => v > 0 || 'Quantidade deve ser maior que 0'
              ]"
            /> 
          </td>
          <td> {{ product.units.name }} ({{ product.units.symbol }}) </td>
          <td> {{ product.name }} </td>
          <td style="min-width: 100px">
            <v-text-field 
              class="mt-4"
              v-model.lazy="product.unit_value"
              outlined
              variant="solo-filled"
              density="compact"
              v-money="{
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                precision: 2,
                masked: true
              }"
              :disabled="disabled"
              min="0"
              @keyup="updateTotal(product)"
              :rules="[v => !!v || 'Campo obrigatório',
                v => parseFloat((v.match(/\d/g).join('')) / 100 || 0) > 0 || 'Valor deve ser maior que 0'
              ]"
            >
            </v-text-field>
          </td>

          <td class="text-end" style="min-width: 100px">
            <v-text-field 
              class="mt-4"
              v-model.lazy="product.total"
              outlined
              variant="solo-filled"
              density="compact"
              v-money="{
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                precision: 2,
                masked: true
              }"
              :disabled="disabled"
              min="0"
              :rules="[v => !!v || 'Campo obrigatório',
                v => parseFloat((v.match(/\d/g).join('')) / 100 || 0) > 0 || 'Valor deve ser maior que 0'
              ]"
            >
            </v-text-field>
          </td>

          <td style="width: 50px">
            <v-btn
              color="error"
              :disabled="disabled"
              @click="removeProduct(index)"
              :icon="true"
              size="24"
            >
              <v-icon size="12">mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>

      <tbody>
        <tr>
          <td colspan="6" class="text-center" v-if="note.products.length === 0">
            Nenhum produto adicionado
          </td>
        </tr>
      </tbody>

      <tfoot>
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th class="text-end">
            <div> Desconto: <strong class="text--error"> {{ formattedDiscount }} </strong> </div>
            <div>Total da nota: {{ moneyFormat(total) }} </div>
          </th>
        </tr>
      </tfoot>
    </v-table>
  </div>
</template>

<script>
  export default {
    props: {
      note: {
        type: Object,
        required: true,
      },

      total: {
        type: Number,
        required: true,
        default: 0,
      },

      disabled: {
        type: Boolean,
        default: false,
      },

      discountValue: {
        type: Number,
        default: 0,
      },
    },

    data () {
      return {
        product: null,
        products: [],
      }
    },

    computed: {
      productsFiltered () {
        return this.products.filter((p) => this.note.products.findIndex((item) => item.product_id === p.id) === -1);
      },

      formattedDiscount () {
        if (this.discountValue > 0) {
          return '-' + this.$money(this.discountValue);
        } else {
          return this.$money(this.discountValue);
        }
      }
    },

    async mounted () {
      await this.getProducts()
    },

    watch: {
      product (prd) {
        if (!prd) return;
        if (!this.note.products) return;

        const alreadyExists = this.note.products.find(item => item.product_id === prd.id);
        if (!alreadyExists) this.addProductToNote(prd);
        this.product = null
      },
    },

    methods: {
      updateTotal (product) {
        const totalLine = product.amount * this.$stringToNumber(product.unit_value) || 0;
        product.total = this.moneyFormat(totalLine);
      },

      addProductToNote (product) {
        if (!product) return console.log('Produto não informado');
        const productAmount = 1;
        const line = {
          amount: productAmount,
          product_id: product.id,
          quantity: product.quantity,
          units_of_measurement_id: product.units_of_measurement_id,
          name: product.name,
          description: product.description,
          unit_value: product.price_sale,
          total: (productAmount * this.$stringToNumber(product.price_sale)).toString() || 0,
          units: product.units,
        }
        
        this.$emit('add-product', line);
      },

      removeProduct (idx) {
        this.$emit('remove-product', idx);
      },
      
      moneyFormat(value = 0) {
        return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
      },

      customFilter (itemTitle, queryText, item) {
        const productName = item.name.toLowerCase()
        const productDescription = item.description.toLowerCase()
        const searchText = queryText.toLowerCase()
        return productName.indexOf(searchText) > -1 || productDescription.indexOf(searchText) > -1
      },

      async getProducts () {
        const [response, error] = await this.$handleAsync(this.$axios.get('/products'));
        if (!error && response.data.success) {
          const products = response.data.data.map((product) => {
            if (product.quantity <= 0) {
              product.disabled = true;
            }

            return product;
          });

          this.products = products;
        }
      },
    },
  }
</script>

<style>
.v-btn--variant-tonal {
  background: #73db34 !important;
  color: black !important;
}
</style>