<template>
    <v-card class="pa-2">
        <v-card-title>
            {{ editingIndex !== null ? 'Editar' : 'Novo' }} Cliente
        </v-card-title>

        <v-form ref="form" class="px-4">
            <v-row>
                <v-col cols="12" lg="12" class="pb-0">
                    <v-text-field variant="solo-filled" :rules="item.rules.name" v-model="modalData.name" label="Nome" required :disabled="saveLoading"></v-text-field> 
                </v-col>

                <v-col cols="12" class="pb-0 mb-0">
                    <h4> Endereço </h4>
                </v-col>

                <v-col cols="12" lg="6" class="pb-0">
                    <v-text-field variant="solo-filled" 
                    :rules="[maxChar(1024)]" 
                    v-model="modalData.zipcode" 
                    v-mask="'#####-###'" 
                    label="CEP" 
                    required 
                    :disabled="saveLoading"
                    @blur="getAddress()" 
                    :error-messages="cepError" 
                    validate-on-blur 
                ></v-text-field> 
                </v-col>

                <v-col cols="12" lg="6" class="pb-0">
                    <v-text-field variant="solo-filled" :rules="[maxChar(1024)]" v-model="modalData.address" label="Endereço" required :disabled="saveLoading"></v-text-field> 
                </v-col>

                <v-col cols="12" lg="4" class="pb-0">
                    <v-text-field variant="solo-filled" :rules="[maxChar(1024)]" v-model="modalData.neighborhood" label="Bairro" required :disabled="saveLoading"></v-text-field> 
                </v-col>

                <v-col cols="12" lg="4" class="pb-0">
                    <v-text-field variant="solo-filled" :rules="[maxChar(225)]" v-model="modalData.number" label="Número" required :disabled="saveLoading"></v-text-field> 
                </v-col>

                <v-col cols="12" lg="4" class="pb-0">
                    <v-text-field variant="solo-filled" :rules="[maxChar(1024)]" v-model="modalData.complement" label="Complemento" required :disabled="saveLoading"></v-text-field> 
                </v-col>

                <v-col cols="12" class="pb-0 mb-0">
                    <h4> Contato </h4>
                </v-col>

                <v-col cols="12" lg="6" class="pb-0">
                    <v-text-field 
                        variant="solo-filled" 
                        :rules="item.rules.required" 
                        v-model="modalData.register" 
                        label="CPF/CNPJ" 
                        required 
                        :disabled="saveLoading"
                        v-mask="['###.###.###-##', '##.###.###/####-##']"
                    ></v-text-field> 
                </v-col>

                <v-col cols="12" lg="6" class="pb-0">
                    <v-text-field 
                        variant="solo-filled" 
                        :rules="[maxChar(25)]" 
                        v-model="modalData.phone" 
                        label="Telefone"
                        required 
                        :disabled="saveLoading"
                        v-mask="['(##) #####-####', '(##) ####-####']"
                        ></v-text-field> 
                </v-col>

                <v-col cols="12" lg="12" class="pb-0">
                    <v-text-field variant="solo-filled" :rules="[maxChar(1024)]" v-model="modalData.email" label="E-mail" required :disabled="saveLoading" hide-details="auto"></v-text-field>
                </v-col>

                <v-col cols="12">
                    <v-checkbox v-model="modalData.email_auto_send" label="Enviar faturas automaticamente" :disabled="saveLoading"></v-checkbox>
                </v-col>

                <v-col cols="12" lg="12" class="pb-0">
                    <v-textarea variant="solo-filled" :rules="[maxChar(2048)]" v-model="modalData.observation" label="Observação" required :disabled="saveLoading"></v-textarea>
                </v-col>
            </v-row>
        </v-form>

        <v-card-actions class="px-4">
            <v-spacer></v-spacer>
            <v-btn color="darken-1" text @click="close" :disabled="saveLoading"> Cancelar </v-btn>
            <v-btn color="green" @click="saveItem" :disabled="saveLoading"> Salvar </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        props: {
            client: {
                type: Object,
                default: () => ({}),
            },
            editingIndex: {
                type: Number,
                default: -1,
            },
        },
        data() {
            return {
                item: {
                    rules: {
                        name: [
                            v => !!v || 'Nome é obrigatório',
                            v => (v && v.length <= 255) || 'Nome deve ter no máximo 255 caracteres',
                        ],
                        required: [
                            v => !!v || 'Campo obrigatório',
                        ],
                    },
                },

                saveLoading: false,
                defaultData: {
                    id: null,
                    name: '',
                    zipcode: '',
                    address: '',
                    neighborhood: '',
                    number: '',
                    register: '',
                    complement: '',
                    phone: '',
                    email: '',
                    condiction: '',
                    observation: '',
                    email_auto_send: false,
                },

                cepError: '',
            }
        },

        computed: {
            modalData: {
                get() {
                    return this.client;
                },
                set(value) {
                    this.$emit('update:client', value);
                }
            },
        },

        methods: {
            maxChar(maxAmount) { 
                // if value doesn't exist, return true (valid)
                return v => !v || v.length <= maxAmount || `Máximo de ${maxAmount} caracteres`;
            },

            clearItem (closing = true) {
                if (closing) {
                    this.$emit('close');
                }

                this.modalData = Object.assign({}, this.defaultData);
            },

            close () {
                this.clearItem();
            },

            async getAddress () {
                try {
                    const response = (await this.$axios.get(`https://viacep.com.br/ws/${this.modalData.zipcode}/json/`)).data
                    const { logradouro, bairro, localidade, uf } = response;

                    if (!logradouro) throw new Error('CEP inválido')

                    this.modalData.address = logradouro
                    this.modalData.neighborhood = bairro
                    this.modalData.city = localidade
                    this.modalData.state = uf

                    this.cepError = '';
                   
                } catch (error) {
                    this.modalData.address = null
                    this.modalData.neighborhood = null
                    this.modalData.city = null
                    this.modalData.state = null

                    this.cepError = 'CEP inválido'
                }
            },

            async saveItem() {
                const { valid } = await this.$refs.form.validate();
                if (!valid) {
                    return false;
                }

                try {
                    this.saveLoading = true;

                    this.modalData.phone = this.$toNumeric(this.modalData.phone);
                    this.modalData.register = this.$toNumeric(this.modalData.register);
                    this.modalData.zipcode = this.$toNumeric(this.modalData.zipcode);

                    if (this.modalData.id) {
                        await this.$axios.put(`/client/${this.modalData.id}`, this.modalData);
                    } else {
                        await this.$axios.post('/client', this.modalData);
                    }

                    this.clearItem();
                    this.$emit('refresh');
                    this.$notify({ title: 'Sucesso!', text: 'Ação realizada com sucesso!', color: 'success' });
                } catch (error) {
                    if (error.response) {
                        const { data } = error.response;

                        let message = '';
                        if (data && data.data) {
                            for (const key in data.data) {
                                if (Object.hasOwnProperty.call(data.data, key)) {
                                    const element = data.data[key];
                                    message += element[0] + '\n';
                                }
                            }
                        }

                        this.$notify({ title: 'Erro!', text: message, icon: 'error' });
                    }
                }

                this.saveLoading = false;
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>