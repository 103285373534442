import axios from 'axios';
import store from '@/store';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
export const checkAuth = async (to, from, next) => {
  if (!localStorage.getItem('_token')) {
    next('/login');
    return;
  }
  
  axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('_token')}`;

  if (store.state.user) {
    return next();
  }

  try {
    const getUser = await axios.get('/get-user');
    if (!getUser.data) {
      localStorage.removeItem('_token');
      next('/login');
      return;
    }

    store.commit('setUser', getUser.data);
    store.commit('setLogged', true);
    next();
  } catch (error) {
    localStorage.removeItem('_token');
    next('/login');
  }
};

export const isLogged = async (to, from, next) => {
  // só vamos verificar se o user tem o token, ao se redirecionado,
  // ele já vai ser verificado, evitando assim uma requisição desnecessária
  const token = localStorage.getItem('_token');
  if (token) {
    next('/');
    return;
  }

  next();
}

export const doLogout = async (to, from, next) => {
  store.dispatch('doLogout')
  next('/login');
}