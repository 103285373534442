<template>
    <v-card class="pa-5 d-flex flex-column">
        <v-row class="mb-4" align="center">
            <v-col cols="12" lg="4">
                <h2> Gerenciamento de Clientes </h2>
            </v-col>

            <v-col cols="12" lg="4">
                <v-text-field
                    :loading="client.loading"
                    :disabled="client.loading"
                    v-model="client.search"
                    density="compact"
                    variant="solo-filled"
                    label="Pesquisar por nome, CPF ou CNPJ"
                    append-inner-icon="mdi-magnify"
                    single-line
                    hide-details
                ></v-text-field>
            </v-col>

            <v-col cols="12" lg="3" class="text-right">
                <v-btn color="primary" @click="showModal">
                    Adicionar Cliente
                </v-btn>
            </v-col>
        </v-row>

        <ListCRUD :headers="client.headers" :items="client.items" :loading="client.loading" :search="client.search" @action:edit="showModal" @action:delete="deleteItem" @action:refresh="fetchItems" />

        <v-dialog v-model="modal" max-width="clamp(50%, 500px, 100%)" persistent>
            <client-modal :client="modalData" :editingIndex="editingIndex" @close="clearItem" @refresh="fetchItems" />
        </v-dialog>

    </v-card>  
</template>

<script>
import Swal from 'sweetalert2';
import ListCRUD from '../ListCRUD.vue'
import ClientModal from '../modal/clientModal.vue';
export default {
    components: { ListCRUD, ClientModal },
    data() {
        return {
            client: {
                items: [],
                search: '',
                loading: true,
                headers: [
                    { title: 'ID', key: 'id' },
                    { title: 'Nome', key: 'name' },
                    { title: 'CPF/CNPJ', key: 'register' },
                    { title: 'Telefone', key: 'phone' },
                    { title: 'E-mail', key: 'email' },
                    { title: 'Ações', key: 'actions', sortable: false },
                ],

                rules: {
                    name: [
                        v => !!v || 'Nome é obrigatório',
                        v => v.length <= 255 || 'Nome deve ter no máximo 255 caracteres',
                    ],

                    required: [
                        v => !!v || 'Campo obrigatório',
                    ]
                }
            },

            modal: false,
            modalData: {},
            dataDefault: {
                id: null,
                name: '',
                zipcode: '',
                address: '',
                neighborhood: '',
                number: '',
                register: '',
                complement: '',
                phone: '',
                email: '',
                condiction: '',
                observation: '',
            }
        }
    },

    async mounted () {
        await this.fetchItems();
    },

    methods: {
        maxChar(maxAmount) { 
            // if value doesn't exist, return true (valid)
            return v => !v || v.length <= maxAmount || `Máximo de ${maxAmount} caracteres`;
        },

        clearItem (closing = true) {
            if (closing) {
                this.modal = false;
            }

            this.modalData = Object.assign({}, this.dataDefault);
        },
        
        showModal(item) {
            this.modal = true;
            this.modalData = Object.assign({}, item);
            this.editedIndex = this.client.items.indexOf(item);
        },

        async fetchItems() {
            this.client.loading = true;

            const [response, error] = await this.$handleAsync(this.$axios.get('/clients'));
            if (!error) this.client.items = response.data.data;

            this.client.loading = false;
        },

        async deleteItem(item) {
            Swal.fire({
                title: 'Tem certeza?',
                text: "Você não poderá reverter isso!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sim, deletar!',
                cancelButtonText: 'Não, cancelar!',
                reverseButtons: true,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await this.deleteClient(item);
                }
            })
        },

        async deleteClient(item) {
            try {
                await this.$axios.delete(`/client/${item.id}`);
                
                this.$notify({
                    title: 'Sucesso!',
                    text: 'Ação realizada com sucesso!',
                    icon: 'success',
                })
                await this.fetchItems();
            } catch (error) {
                console.log(error);
            }
        },
    }
}
</script>

<style lang="scss" scoped>

</style>