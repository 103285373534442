import axiosInstance from '@/plugins/axios.js';
const $https = axiosInstance.$https;

export default {
    async fetch ({ endpoint, params, headers, method }) {
        try {
            const response = await $https[method](endpoint, params, headers);
            return [response, null];
        } catch (error) {
            return [null, error];
        }
    },

    async getAlerts() {
        try {
            const response = await $https.get('/products/with-alerts');
            return [response, null];
        } catch (error) {
            return [null, error];
        }
    }
}
