const stringToNumber = {
    install (app) {
        app.config.globalProperties.$toNumeric = (value) => {
            if (!value) return value;

            return value.replaceAll(/\D/g, ""); // remove all non numeric characters
        };

        app.config.globalProperties.$handleAsync = async (operation) => {
            try {
				const response = await operation;
				return [response, null];
			} catch (error) {
				return [null, error];
			}
        };

        app.config.globalProperties.$stringToNumber = (value) => {
            if (!value) return value;
            return parseFloat(value.replace(/^(R\s*\$|\s+)/g, '').replace(/\./g, '').replace(',', '.')) || 0;
        };

        app.config.globalProperties.$money = (value) => {
            return new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
            }).format(value);
        };

        app.config.globalProperties.$minValueZero = (value) => {
            if (typeof value === "number") {
                return value > 0 || "Valor deve ser maior que 1";
            }

            if (!value) return false;

            const numericValue = value.replace(/\D/g, "");
            return !numericValue || numericValue > 0 || "Valor deve ser maior que 0";
        },
            
        app.config.globalProperties.$leftPad = (value, length, char = 0) => {
            const str = value.toString();
            return str.padStart(length, char);
        },

        app.config.globalProperties.$formatNumber = (type = "phone", number) => {
            if (!number) return;
            number = number.toString();
    
            const formats = {
              cep: `${number.replace(/(\d{5})(\d{3})/, "$1-$2")}`,
              phone: `${number.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3")}`,
              cpf: `${number.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")}`,
              cnpj: `${number.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")}`,
            }
    
            return formats[type] || number;
        }

        app.config.globalProperties.$maxChars = (text, size) => {
            if (!text) return '';
            if (text.length > size) {
                return text.substring(0, size) + '...';
            }
            return text;
        }
    }
}

export default stringToNumber;