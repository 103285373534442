<template>
    <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :search="search"
        item-key="id"
        items-per-page="10"
        max-width="100%"
        density="compact"
    >
        <template v-slot:top>
            <slot name="header"> </slot>
        </template>

        <template v-slot:[`item.state`]="{ item }">
            <v-chip :color="saleStatus[item.raw.state].color"> {{ saleStatus[item.raw.state].text }} </v-chip>
        </template>

        <template v-slot:[`item.description`]="{ item }">
            <v-tooltip location="top" max-width="500" :text="item.raw.description">
                <template v-slot:activator="{ props }">
                    <span v-bind="props"> {{ maxChars(item.raw.description, 32) }} </span>
                </template>
            </v-tooltip>

            <!-- theres no description -->
            <span v-if="!item.raw.description"> Nenhuma descrição </span>
        </template>

        <template v-slot:[`item.cost`]="{ item }">
            <span> {{ $money(item.raw.cost) }} </span>
        </template>

        <template v-slot:[`item.price_sale`]="{ item }">
            <span> {{ $money(item.raw.price_sale) }} </span>
        </template>        

        <template v-slot:[`item.price_purchase`]="{ item }">
            <span> {{ $money(item.raw.price_purchase) }} </span>
        </template>

        <template v-slot:[`item.profit_margin`]="{ item }">
            <span> {{ item.raw.profit_margin }}% </span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
            <v-btn size="small" color="white" icon="mdi-pencil" variant="plain" @click="editItem(item.raw)">
            </v-btn>

            <v-btn size="small" color="red" icon="mdi-delete" variant="plain" @click="deleteItem(item.raw)" v-if="showDelete">
            </v-btn>
        </template>

        <template v-slot:no-data>
            <v-btn
                color="primary"
                @click="refresh"
                :loading="loading"
                :disabled="loading"
                class="my-5"
            >
                {{ refreshText }}
            </v-btn>
        </template>

        <template v-slot:bottom v-if="!footer"> </template>

        <!-- criando slot para cada header que não está sendo usado, para que possa ser usado em outros componentes -->
        <template v-for="header in headersToSlot" v-slot:[`item.${header.key}`]="{ item }">
            <slot :name="header.key" :item="item">
                {{ item.raw[header.key] }}
            </slot>
        </template>
    </v-data-table>
</template>

<script>
export default {
    props: {
        dense: {
            type: Boolean,
            default: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        refreshText: {
            type: String,
            default: 'Atualizar'
        },
        newItemText: {
            type: String,
            default: 'Novo Item'
        },
        itemsPerPage: {
            type: Number,
            default: 5
        },
        items: {
            type: Array,
            default: () => []
        },
        search: {
            type: String,
            default: ''
        },
        headers: {
            type: Array,
            default: () => []
        },
        showDelete: {
            type: Boolean,
            default: true
        },
        footer: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            saleStatus: {
                RA: { text: 'Rascunho', color: 'orange' },
                CA: { text: 'Cancelado', color: 'red' },
                CO: { text: 'Confirmado', color: 'green' },
                AN: { text: 'Anulado', color: 'red' },
            },
            
            usedSlots: ['state', 'description', 'cost', 'price_sale', 'price_purchase', 'profit_margin', 'actions',],
        }
    },

    computed: {
        headersToSlot () {
            return this.headers.filter(header => !this.usedSlots.includes(header.key));
        }
    },

    methods: {
        editItem(item) {
            this.$emit('action:edit', item);
        },
        deleteItem(item) {
            this.$emit('action:delete', item);
        },
        refresh() {
            this.$emit('action:refresh');
        },
        newItem() {
            this.$emit('action:new');
        },

        maxChars(text, size) {
            if (!text) return '';
            if (text.length > size) {
                return text.substring(0, size) + '...';
            }
            return text;
        }
    }
};
</script>