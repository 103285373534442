<template>
  <v-footer color="background">
    <div class="text-center w-100 py-5">
      Desenvolvido por <a class="text-white text-decoration-none" href="http://siapp.one" target="_blank"><strong> SIAPP TECNOLOGIA</strong></a> - {{ new Date().getFullYear() }}
    </div>
  </v-footer>
</template>


<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>