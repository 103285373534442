<template>
    <div>
        <v-dialog v-model="waitingState" persistent width="auto" :absolute="true">
            <v-card class="pa-3" v-if="waitingState">
                <v-card-text class="text-center">
                    <v-progress-circular indeterminate size="36" color="primary" class="mb-4"></v-progress-circular>
                    <h3 class="pa-2"> {{ currentStep }} </h3>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-row class="mb-4 pa-0 ma-0" align="center" justify="space-between">
                        <h1> Documento de Compra </h1>
                        <v-btn color="primary" @click="$router.push('/stocks')"> Voltar para lista </v-btn>
                    </v-row>

                    <v-card class="pa-2">
                        <v-form @submit.prevent="submitForm" class="px-4" ref="newStockForm" fast-fail>
                            <v-container>
                                <!-- data da nota -->
                                <v-row>
                                    <v-col cols="12">
                                        <h1 class="text-center"> {{ formData.id ? `${formData.state == 'AN' ? "Visualizando" : "Editando"} documento #${formData.document_number}` : "Novo documento" }} </h1>
                                    </v-col>

                                    <v-col cols="12" v-if="formData.allowedActions && formData.allowedActions.length">
                                        <h2 class="mb-2"> O que deseja fazer? </h2>

                                        <v-btn
                                            v-for="(item, index) in formData.allowedActions"
                                            :key="`btn-${index}`"
                                            class="white--text mr-4"
                                            :color="item.color"
                                            @click="updateState({ state: item.type })"
                                            :disabled="submit"
                                            :loading="submit"
                                        >
                                            {{ item.name }}
                                        </v-btn>
                                    </v-col>

                                    <v-col cols="12" sm="3">
                                        <v-text-field :disabled="!showCreate" v-model="formData.document_number" label="Numero do documento" variant="solo-filled" density="compact" readonly="" placeholder="O número será gerado automaticamente"></v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="3">
                                        <v-select v-model="formData.warehouse_id" readonly="" disabled="" label="Armazém" item-title="name" required variant="solo-filled" density="compact"></v-select>
                                    </v-col>

                                    <v-col cols="12" sm="3">
                                        <v-select v-model="formData.supplier_id" label="Fornecedor" item-title="name" required variant="solo-filled" item-value="id" density="compact" :items="suppliers" :readonly="!showCreate" :disabled="!showCreate"></v-select>
                                    </v-col>

                                    <v-col cols="12" sm="3">
                                        <v-btn class="w-100" color="primary" @click="openSupplierModal" v-if="showCreate"> Novo Fornecedor </v-btn>

                                        <v-dialog v-model="supplier.show" max-width="clamp(50%, 500px, 100%)">
                                            <v-card class="pa-2">
                                                <v-card-title class="font-weight-bold"> Novo Fornecedor </v-card-title>
                                                <company-form getCPNJ v-model="supplier.items" :loading="supplier.loading" @on:submit="submitSupplier" @on:close="closeSupplierModal"></company-form>
                                            </v-card>
                                        </v-dialog>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <!-- <v-col cols="12" sm="3">
                                        <v-select v-model="formData.state" :readonly="showCreate" :disabled="showCreate" label="Status do Documento" :items="documentType" required variant="solo-filled" density="compact"></v-select>
                                    </v-col> -->

                                    <v-col cols="12" sm="3">
                                        <v-select
                                            v-model="formData.document_type_id"
                                            label="Tipo de documento"
                                            :items="documentTypes"
                                            required
                                            variant="solo-filled"
                                            density="compact"
                                            item-title="name"
                                            item-value="id"
                                            :readonly="!showCreate"
                                            :disabled="!showCreate"
                                        ></v-select>
                                    </v-col>

                                    <v-col cols="12" sm="3">
                                        <v-text-field 
                                            v-model="formData.date" 
                                            label="Data da compra" 
                                            type="datetime-local" 
                                            required 
                                            variant="solo-filled"
                                            density="compact"
                                            :readonly="!showCreate"
                                            :disabled="!showCreate"
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12">
                                        <v-divider class="mb-6"></v-divider>

                                        <v-row class="mb-8 mb-lg-2">
                                            <v-col class="d-flex flex-column flex-lg-row">
                                                <h2> Lista de Produtos </h2>
                                                <v-spacer></v-spacer>
                                                <v-btn color="secondary" @click="productModal = !productModal" v-if="showCreate"> Cadastrar Produto </v-btn>
                                            </v-col>
                                        </v-row>
                                                                                
                                        <v-card rounded="lg" border v-for="(line, index) in formData.lines" :key="index" flat="" class="mb-6"> 
                                            <v-card-title class="d-flex flex-column flex-lg-row align-baseline mb-4 bg-v-slider-thumb__surface">
                                                <span class="font-weight-bold"> Produto #{{ index + 1 }} </span>

                                                <v-spacer></v-spacer>

                                                <div v-if="showCreate" class="d-flex flex-column flex-lg-row">
                                                    <v-btn class="mr-0 mr-lg-2 mb-2 mb-lg-0" color="primary" prepend-icon="mdi-plus" variant="outlined" density="compact" @click="addLine" v-if="index === formData.lines.length - 1"> Produto </v-btn>
                                                    <v-btn color="error" prepend-icon="mdi-delete" variant="outlined" density="compact" @click="formData.lines.splice(index, 1)" :disabled="formData.lines.length === 1"> Remover </v-btn>
                                                </div>                                            
                                            </v-card-title>

                                            <v-card-text>
                                                <v-divider class="mb-5" v-if="index > 0"></v-divider>

                                                <v-row>
                                                    <v-col cols="12">
                                                        <v-autocomplete 
                                                            label="Produto"
                                                            v-model="formData.lines[index]"
                                                            :items="productListFiltered" 
                                                            :filter="customFilter"
                                                            item-title="name" 
                                                            item-value="id" 
                                                            outlined
                                                            dense
                                                            clearable
                                                            return-object
                                                            density="compact"
                                                            variant="solo-filled"
                                                            :disabled="!showCreate"
                                                            :readonly="!showCreate"
                                                            :rules="[rules.required]"
                                                        >
                                                            <template v-slot:item="{ props, item }">
                                                                <v-list-item
                                                                    v-bind="props"
                                                                    class="mb-2 pb-4"
                                                                    :title="item.raw.description"
                                                                >
                                                                    <template v-slot:title>
                                                                        <v-tooltip location="top">
                                                                            <template v-slot:activator="{ props }">
                                                                                <v-list-item-title v-bind="props"> {{ $maxChars(item.raw.name, 20) }} </v-list-item-title>
                                                                            </template>

                                                                            <span> Nome: <b>{{ item.raw.name }} </b> <br /> Descrição <b> {{ item.raw.description || 'Nenhuma descrição disponível' }} </b> </span>
                                                                        </v-tooltip>
                                                                    </template>
                                                                    
                                                                    <template v-slot:[`subtitle`]>
                                                                        <strong>
                                                                            {{ item?.raw?.quantity }}
                                                                            {{ item?.raw?.units?.symbol }} disponíveis
                                                                        </strong>
                                                                    </template>
                                                                </v-list-item>
                                                            </template>
                                                        </v-autocomplete>
                                                    </v-col>
                                                    
                                                    <v-col cols="12">
                                                        <v-row>
                                                            <v-col cols="12" md="">
                                                                <!-- add an regex to allow only numbers -->
                                                                <v-text-field 
                                                                    v-model="formData.lines[index].qtd" 
                                                                    label="Qtd" 
                                                                    required 
                                                                    variant="solo-filled" 
                                                                    density="compact"
                                                                    regex="^[0-9]*$"
                                                                    :disabled="!showCreate"
                                                                    :readonly="!showCreate"
                                                                    :rules="[rules.required, rules.number]"
                                                                    
                                                                >
                                                                </v-text-field>
                                                            </v-col>

                                                            <v-col cols="12" md="" v-if="formData.lines[index].units">
                                                                <v-text-field
                                                                    v-model="formData.lines[index].units.symbol" 
                                                                    label="Unidade"
                                                                    required 
                                                                    variant="solo-filled" 
                                                                    density="compact"
                                                                    readonly=""
                                                                    :rules="[rules.required]"
                                                                >
                                                                </v-text-field>
                                                            </v-col>

                                                            <v-col cols="12" md="">
                                                                <v-text-field 
                                                                    v-model.lazy="formData.lines[index].price_purchase" 
                                                                    label="Prç. compra"
                                                                    required 
                                                                    variant="solo-filled" 
                                                                    density="compact"
                                                                    :disabled="!showCreate"
                                                                    :readonly="!showCreate"
                                                                    :rules="[rules.required, $minValueZero]"
                                                                    @keyup="calculateProfitMargin(index)"
                                                                    v-money="{decimalCount: 2, precision: 2, allowNegative: false, prefix: 'R$ ', thousands: '.', decimal: ','}"
                                                                ></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" md="">
                                                                <v-text-field 
                                                                    v-model="formData.lines[index].price_sale"
                                                                    label="Prç. venda" 
                                                                    required variant="solo-filled" 
                                                                    density="compact"
                                                                    :disabled="!showCreate"
                                                                    :readonly="!showCreate"
                                                                    :rules="[rules.required, $minValueZero]"
                                                                    @keyup="calculateProfitMargin(index)"
                                                                    v-money="{decimalCount: 2, precision: 2, allowNegative: false, prefix: 'R$ ', thousands: '.', decimal: ','}"
                                                                ></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" md="">
                                                                <v-text-field 
                                                                    v-model="formData.lines[index].profit_margin" 
                                                                    label="Margem %" 
                                                                    required 
                                                                    variant="solo-filled" 
                                                                    density="compact"
                                                                    :disabled="!showCreate"
                                                                    :readonly="!showCreate"
                                                                    :rules="[rules.required]"
                                                                    @keyup="calculateSalePrice(index)"
                                                                    v-money="{decimalCount: 0, precision: 2, allowNegative: false, prefix: '', suffix: '%', thousands: '.', decimal: ','}"
                                                                ></v-text-field>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                                    
                                <v-btn color="primary" :disabled="submit" type="submit" v-if="showCreate"> Salvar </v-btn>
                            </v-container>
                        </v-form>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <v-dialog v-model="productModal" persistent max-width="clamp(50%, 500px, 100%)">
            <product-modal
                :product="productModalData"
                @close="closeProductModal"
                @refresh="refreshProducts"
            />
            </v-dialog>
    </div>
</template>

<script>
    import { constants } from '@/mixins/constants';
    import CompanyForm from '@/components/CompanyForm.vue';
    import ProductModal from '@/components/Base/modal/productModal.vue';
    export default {
        mixins: [constants],
        components: { CompanyForm, ProductModal },
        data() {
            return {
                submit: false,
                showCreate: false,

                currentStep: '',
                waitingState: false,
                
                productList: [],
                
                productModal: false,
                productModalData: {},
                productDefault: {
                    id: null,
                    name: "",
                    description: "",
                    price_sale: 0,
                    price_purchase: 0,
                    profit_margin: 0,
                    category_id: "",
                    quantity: 0,
                },

                defaultFormData: {
                    state: "RA",
                    supplier_id: null,
                    warehouse_id: { id: 1, name: 'Sede' },
                    document_type_id: 1,
                    date: new Date().toISOString().substring(0, 19).replace('T', ' '),
                    document_number: null,
                    lines: [],
                },

                formData: {}, 
                suppliers: [],
                documentTypes: [],

                rules: {
                    required: value => !!value || 'Campo obrigatório.',
                    number: value => !isNaN(value) || 'Campo deve ser um número.',
                },

                supplier: {
                    items: {},
                    show: false,
                    loading: false,
                }
            }
        },

        async mounted() {
            const { id } = this.$route.params
            
            Promise.all([
                this.getItem(id),
                this.getProducts(),
                this.getDocumentTypes(),
                this.getSuppliers(),
            ]).then(([item, products]) => {
                this.item = item
                this.productList = products
            })
        },

        computed: {
            productListFiltered () {
                return this.productList.filter(product => {
                    return !this.formData.lines.find(line => line?.id === product.id)
                })
            }
        },

        watch: {
            // não gostei dessa solução, mas não consegui pensar em outra forma de fazer
            // se o usuário clicar fora do autocomplete, o valor é null, então remove a linha
            'formData.lines': {
                handler: function (val) {
                    val.forEach((line, index) => {
                        if (line === null) {
                            this.formData.lines.splice(index, 1)
                            this.addLine()
                        } else {
                            line.qtd = !this.formData?.id ? this.formData.lines[index].qtd : line.quantity || 0
                        }

                    })
                    
                },
                deep: true
            },

            '$route.params.id': {
                handler: function (val) {
                    this.getItem(val)
                },
                deep: true
            },
        },

        methods: {
            async getItem(id) {
                // if (!id) return this.$router.push('/stocks');
                if (!id || id === 'new') {
                    this.showCreate = true;
                    this.formData = Object.assign({}, this.defaultFormData, { lines: [] });
                    this.addLine();
                    return 
                }

                const [response, error] = await this.$handleAsync(this.$axios.get(`/stock/${id}`));
                if (error) return console.log('Erro ao buscar estoque');

                Object.assign(this.formData, response.data.data);
            },

            async getProducts () {
                const [response, error] = await this.$handleAsync(this.$axios.get(`/products`));
                if (!error) return response.data.data
            },

            async getDocumentTypes () {
                const [response, error] = await this.$handleAsync(this.$axios.get(`/document/type/list`));
                if (!error) this.documentTypes = response.data.data.stock_types
            },

            async getSuppliers () {
                const { data: suppliers } = (await this.$axios.get(`/suppliers`).catch(err => console.log(err))).data;
                this.suppliers = suppliers
            },

            async submitForm() {
                const { valid } = await this.$refs.newStockForm.validate();
                if (!valid) return;

                // clear empty products lines
                if (this.formData.lines.length > 1) {
                    const newLines = this.formData.lines.filter(line => line && line.product_id !== null)
                    if (newLines.length === 0) return this.$notify({ icon: 'error', text: 'Adicione pelo menos um produto' })
                    
                    this.formData.lines = this.formData.lines.filter(line => line.product_id !== null)
                }
                
                const products = this.formData.lines.map(line => {
                    return {
                        product_id: line.id || line.product_id,
                        quantity: line.qtd,
                        units_of_measurement_id: line.units_of_measurement_id,
                        name: line.name,
                        price_purchase: this.$stringToNumber(line.price_purchase),
                        price_sale: this.$stringToNumber(line.price_sale),
                        profit_margin: this.$stringToNumber(line.profit_margin),
                    }
                })

                this.submit = true

                const url = this.showCreate ? '/stock' : `/stock/${this.formData.id}`
                const method = this.showCreate ? 'post' : 'put'

                try {
                    const formData = {
                        ...this.formData,
                    }
                    
                    formData.lines = products
                    formData.warehouse_id = formData.warehouse_id.id

                    delete formData.document_number;

                    const req = (await this.$axios[method](url, formData)).data
                    const { success, data, } = req
    
                    if (!success) return this.$notify({ icon: 'error', text: 'Erro ao salvar nota' })
                    
                    this.showCreate = false
                    this.$notify({ icon: 'success', text: 'Nota salva com sucesso' })

                    if (!this.formData.id) {
                        this.$router.push(`/stock/${data.id}`)
                    }

                    await this.getItem(data.id)
                } catch (error) {
                    const { errors } = error?.response?.data || {}
                    if (errors) {
                        for (const key in errors) {
                            this.$notify({ title: 'Erro!', icon: 'error', text: errors[key] })
                        }
                    }
                }

                this.submit = false
                return true;
            },

            async updateState ({ state }) {
                const allowedStates = ['create_pdf', '2_via', 'replace_pdf', 'tmp-file', 'send_email'];

                if (!allowedStates.includes(state)) {
                    this.formData.state = state;

                    if (await this.submitForm()) {
                        if (state === 'CO') {
                            this.setLoadingState(true, 'Salvando documento, por favor aguarde...');
                            await this.handleCOState();
                        }
                    }
                }

                if (state === 'create_pdf' || state === 'replace_pdf') {
                    await this.handleCOState();
                }

                if (state === 'tmp-file') {
                    await this.handleCOState(true);
                }

                if (state === '2_via') {
                    await this.downloadDoc();
                }
            },

            addLine() {
                this.formData.lines.push({
                    product_id: null,
                    quantity: 0,
                    qtd: 0,
                    name: null,
                    units_of_measurement_id: null,
                    price_purchase: null,
                    price_sale: null,
                    profit_margin: null,
                    units: {
                        id: null,
                        symbol: null,
                    }
                });
            },

            customFilter (itemTitle, queryText, item) {
                const productName = item.name.toLowerCase()
                const productDescription = item.description.toLowerCase()
                const searchText = queryText.toLowerCase()
                return productName.indexOf(searchText) > -1 || productDescription.indexOf(searchText) > -1
            },

            calculateSalePrice(index) {
                const item = this.formData.lines[index];
                
                const price_purchase = this.$stringToNumber(item.price_purchase) || 0;
                const profit_margin = this.$stringToNumber(item.profit_margin) || 0;

                const url = `/products/simulate/${this.formData.id || 0}?price_purchase=${price_purchase}&profit_margin=${profit_margin}`;
                if (price_purchase > 0 && profit_margin > 0) {

                    this.handleAsyncOperation(this.$axios.get(url)).then(([response, error]) => {
                        if (error) return console.log(error);

                        if (response.data.success) {
                            const { price_sales } = response.data.data;
                            item.price_sale = (price_sales).toFixed(2);
                        }
                    });
                }
            },

            calculateProfitMargin(index) {
                const item = this.formData.lines[index];

                const price_sale = this.$stringToNumber(item.price_sale) || 0;
                const price_purchase = this.$stringToNumber(item.price_purchase) || 0;
                
                if (price_sale > 0 && price_purchase > 0) {
                    const url = `/products/simulate/${this.formData.id || 0}?price_sales=${price_sale}&price_purchase=${price_purchase}`;

                    this.handleAsyncOperation(this.$axios.get(url)).then(([response, error]) => {
                        if (error) return console.log(error);

                        if (response.data.success) {
                            const { profit_margin } = response.data.data;
                            item.profit_margin = (profit_margin).toFixed(2);
                        }
                    });
                }
            },

            async handleCOState(tmp = false) {
                try {
                    const generatePDFResult = await this.generatePDF(tmp);
                    const downloadDocResult = generatePDFResult && await this.downloadDoc(tmp);

                    if (downloadDocResult) {
                        this.setLoadingState();
                    }
                } catch (error) {
                    this.$swal({
                        title: 'Algo deu errado',
                        text: error,
                        icon: 'error',
                        confirmButtonText: 'Ok',
                    })
                }
            },

            generatePDF(tmp = false) {
                return new Promise((resolve, reject) => {
                    const msg = tmp ? 'Gerando arquivo temporário, por favor aguarde...' : 'Criando documento, por favor aguarde...';
                    this.setLoadingState(true, msg);
                    this.handleAsyncOperation(this.$axios.post(`/document/stock/pdf/a4/${this.formData.id}`, { tmp }))
                    .then(([resp, error]) => {
                        if (error) {
                            this.$notify({ icon: 'error', text: error.response.data.message });
                            this.setLoadingState();
                            
                            reject("Falha ao gerar documento, tente novamente.");
                        } else if (resp) {
                            this.getItem(this.formData.id).then(() => resolve(true)).catch(err => reject(err));
                            this.setLoadingState();
                        }
                    })
                    .catch(err => {
                        this.setLoadingState();
                        reject(err);
                    });
                });
            },

            downloadDoc(tmp = false) {
                return new Promise((resolve, reject) => {
                    const msg = tmp ? 'Efetuando download arquivo temporário, por favor aguarde...' : 'Efetuando o download do seu arquivo, por favor aguarde...';
                    this.setLoadingState(true, msg);

                    // add a delay to show the loading state to the user
                    setTimeout(() => {
                        this.handleAsyncOperation(this.$axios.get(`/document/stock/pdf/a4/${this.formData.id}`, { tmp, responseType: 'arraybuffer' }) || [])
                        .then(([response, error]) => {
                            if (error) {
                                this.setLoadingState();
                                this.$notify({ icon: 'error', text: error.response.data.message });
                                reject("Falha ao efetuar o download do documento, tente novamente.");
                            } else if (response && response.data) {
                                const blob = new Blob([response.data], { type: 'application/pdf' });
                                const link = document.createElement('a');
                                link.href = window.URL.createObjectURL(blob);
                                link.download = `estoque-${this.formData.document_number}.pdf`;
                                link.click();

                                this.setLoadingState();
                                resolve(true);
                            }
                        })
                        .catch(err => {
                            this.setLoadingState();
                            reject(err);
                        });
                    }, 1500);
                });
            },

            setLoadingState (state = false, currentStep = '') {
                this.waitingState = state;
                this.currentStep = currentStep;
            },

            openSupplierModal() {
                this.supplier.show = true;
            },
            
            closeProductModal () {
                this.productModal = false;
                this.productModalData = Object.assign({}, this.productDefault);
            },

            async refreshProducts () {
                this.productList = await this.getProducts();
            },

            closeSupplierModal() {
                this.supplier.show = false;
                this.supplier.items = {};
            },

            async submitSupplier() {
                this.supplier.loading = true;
                
                const method = 'post';
                const url = `/suppliers`;

                // fix for cep
                const form = { ...this.supplier.items };
                form.cnpj = this.$toNumeric(this.supplier.items.cnpj)
                form.phone = this.$toNumeric(this.supplier.items.phone)
                form.mobile = this.$toNumeric(this.supplier.items.mobile)
                form.address_zipcode = this.$toNumeric(this.supplier.items.address_zipcode)
                
                const [response, error] = await this.handleAsyncOperation(this.$axios[method](url, form));
                
                if (!error && response.data.success) {
                    this.closeSupplierModal();
                    await this.getSuppliers();

                    this.$notify({
                        title: 'Sucesso!',
                        text: 'Ação realizada com sucesso!',
                        icon: 'success',
                    })
                } else {
                    this.$notify({
                        title: 'Erro!',
                        text: 'Erro ao cadastrar fornecedor!',
                        icon: 'error',
                    })
                }

                this.supplier.loading = false;
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>