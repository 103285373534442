<template>
  <div class="d-flex align-center justify-center flex-column h-100">
    <img src="../assets/logo.png" class="logo">

    <h2 class="text-center my-5"> {{ isLogged ? 'Bem-vindo!' : 'Faça login para continuar' }} </h2>

    <v-col cols="12" lg="4" md="6">
      <v-card class="w-lg-25 w-100 pa-3" v-if="!isLogged">
        <v-card-text>
          <v-form ref="form" @submit.prevent class="mb-5">
            <v-text-field
              v-model="email"
              :rules="emailRules"
              label="E-mail"
              required
              density="compact"
              variant="solo-filled"
            ></v-text-field>

            <v-text-field
              v-model="password"
              :rules="passwordRules"	
              label="Senha"
              type="password"
              required
              density="compact"
              variant="solo-filled"
            ></v-text-field>
          </v-form>

          <v-btn
            color="primary"
            class="mr-4"
            block
            :loading="loading"
            :disabled="loading"
            @click="login"
          > Entrar </v-btn>
        </v-card-text>
      </v-card>
    </v-col>


  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  data() {
    return {
      valid: false,
      loading: false,

      email: '',
      password: '', 

      emailRules: [
        value => !!value || 'E-mail é obrigatório.',
        value => /.+@.+\..+/.test(value) || 'E-mail deve ser válido.',
      ],
      passwordRules: [
        value => !!value || 'Senha é obrigatória.',
      ],
    };
  },

  computed: {
    ...mapState(['isLogged']),
  },

  methods: {
    ...mapActions(['doLogin']),
    async login() {
      
      const { valid } = await this.$refs.form.validate();
      if (!valid) return;

      this.loading = true;
      
      try {
        await this.doLogin({ email: this.email, password: this.password });
        this.$router.push('/');
      } catch (error) {
        this.valid = false;
      }

      this.loading = false;

    }
  }
};
</script>

<style scoped>
.logo {
  /* width: 200px; */
  height: 100px;
  /* object-fit: cover; */
  margin-bottom: 36px;
}
</style>