<template>
  <v-app>
    <NavBar />

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>

    <Footer />
  </v-app>
</template>

<script>
import NavBar from '@/components/Base/NavBar.vue'
import Footer from '@/components/Base/FooterSmall.vue'
export default {
  name: 'App',
  components: {
    NavBar,
    Footer,
  },
}

</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  font-family: 'Montserrat', sans-serif !important;
}

.logo {
  margin-left: 20px;
  object-fit: cover;

  transition: all 0.3s ease-in-out;
  max-height: 50px;
  padding: 2px;

  user-select: none;
}
</style>
