<template>
  <v-app-bar color="surface"> 
    <v-app-bar-nav-icon v-if="$vuetify.display.mobile && isLogged" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

    <img src="@/assets/logo_dark.png" class="logo" height="32" alt="logo" />

    <v-spacer />

    <v-tooltip location="bottom" v-if="isLogged">
      <template v-slot:activator="{ props }">
        <v-btn
          icon
          v-bind="props"
          class="ml-auto mr-3"
          @click="$router.push('/')"
        >
          <v-badge
            color="red"
            :content="productAlerts.length || 0"
            overlap
          >
            <v-icon>mdi-package-variant</v-icon>
          </v-badge>
        </v-btn>
      </template>
      <span> Alertas de Estoque </span>
    </v-tooltip>

    <v-menu min-width="200px" rounded v-if="isLogged">
      <template v-slot:activator="{ props }">
        <v-btn
          icon
          v-bind="props"
          class="ml-auto"
        >
          <v-avatar
            color="brown"
            size="42"
          >
            <span class="subtitle-1">{{ isLogged ? initials : 'A' }}</span>
          </v-avatar>
        </v-btn>
      </template>

      <v-card>
        <v-card-text>
          <div class="mx-auto text-center">
            <v-avatar
              color="brown"
            >
              <span class="subtitle-1">{{ isLogged ? initials : 'A' }}</span>
            </v-avatar>
            <h3> {{ user.name }} </h3>
            <p class="text-caption mt-1">
              {{ user.email }}
            </p>
            <v-divider class="my-3"></v-divider>
            <v-btn
              rounded
              variant="text"
              to="/settings"
            >
              Editar Conta
            </v-btn>
            <v-divider class="my-3"></v-divider>
            <v-btn
              rounded
              variant="text"
              @click="doLogout"
            >
              Desconectar
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-menu>

  </v-app-bar>

  <v-navigation-drawer app color="surface" v-model="drawer" v-if="isLogged">
    <v-list nav class="pa-5 fill-height">

      <v-list-item prepend-icon="mdi-view-dashboard" title="Home" to="/"></v-list-item>

      <v-list-group v-model="navItems.stocks" no-action>
        <template v-slot:activator="{ props }">
          <v-list-item
            v-bind="props"
            title="Estoque"
          ></v-list-item>
        </template>

        <v-list-item prepend-icon="mdi-package-variant" title="Entrada" to="/stocks"></v-list-item>
        <v-list-item prepend-icon="mdi-store" title="Nova entrada" to="/stock/new"></v-list-item>
      </v-list-group>

      <v-list-group v-model="navItems.sells" no-action>
        <template v-slot:activator="{ props }">
          <v-list-item
            v-bind="props"
            title="Vendas"
          ></v-list-item>
        </template>

        <v-list-item prepend-icon="mdi-cash" title="Lista de Vendas" to="/sales/"></v-list-item>
        <v-list-item prepend-icon="mdi-notebook" title="Nova venda" to="/sale/new"></v-list-item>
      </v-list-group>

      <v-list-group v-model="navItems.products" no-action>
        <template v-slot:activator="{ props }">
          <v-list-item
            v-bind="props"
            title="Produtos"
          ></v-list-item>
        </template>

        <v-list-item prepend-icon="mdi-package-variant" title="Lista de Produtos" to="/products" class="mb-2"></v-list-item>
        <v-list-item prepend-icon="mdi-view-module" title="Lista de Categorias" to="/categories"></v-list-item>
      </v-list-group>

      <v-list-group v-model="navItems.units" no-action>
        <template v-slot:activator="{ props }">
          <v-list-item
            v-bind="props"
            title="Unidade de Medida"
          ></v-list-item>
        </template>

        <v-list-item prepend-icon="mdi-package-variant" title="Lista de Unidades" to="/units"></v-list-item>
      </v-list-group>

      <v-list-group v-model="navItems.costs" no-action>
        <template v-slot:activator="{ props }">
          <v-list-item
            v-bind="props"
            title="Gestão de Custos"
          ></v-list-item>
        </template>

        <v-list-item prepend-icon="mdi-package-variant" title="Lista de Custos" to="/costs"></v-list-item>
      </v-list-group>

      <v-list-group v-model="navItems.customers" no-action>
        <template v-slot:activator="{ props }">
          <v-list-item
            v-bind="props"
            title="Clientes"
          ></v-list-item>
        </template>

        <v-list-item prepend-icon="mdi-account-group" title="Clientes" to="/customers"></v-list-item>
      </v-list-group>

      <v-list-group v-model="navItems.suppliers" no-action>
        <template v-slot:activator="{ props }">
          <v-list-item
            v-bind="props"
            title="Fornecedores"
          ></v-list-item>
        </template>

        <v-list-item prepend-icon="mdi-account-group" title="Fornecedores" to="/suppliers"></v-list-item>
        <v-list-item prepend-icon="mdi-plus" title="Novo Fornecedor" @click="toCreateSupplier"></v-list-item>
      </v-list-group>

      <v-list-item prepend-icon="mdi-cog" title="Configurações" to="/settings"></v-list-item>
    </v-list>

    <template v-slot:append>
      <div class="pa-5">
        <v-list-item prepend-icon="mdi-logout" title="Sair" @click="doLogout"></v-list-item>

        <v-divider class="my-3"></v-divider>

        <div class="text-center text-caption" v-if="version">
          <span> {{ version }} </span>
        </div>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  export default {

    data() {
      return {
        drawer: true,
        version: null,

        navItems: {
          sells: false,
          products: false,
          units: false,
          customers: false,
          users: false,
          reports: false,
          stocks: false,
          costs: false,
        }
      }
    },

    computed: {
      ...mapState(['isLogged', 'user', 'productAlerts']),

      initials () {
        if (this.user && this.user.name) {
          const names = this.user.name.split(' ');
          return names[0][0] + names[names.length - 1][0].toUpperCase();
        }

        return 'TM';
      },
    },

    watch: {
      isLogged (val) {
        if (val) {
          this.getVersion();
          this.$store.dispatch('getAlerts');
        }
      }
    },

    mounted () {
      if (!this.isLogged) return;
      this.$store.dispatch('getAlerts')
      this.getVersion();
    },

    methods: {
      ...mapActions(['doLogout']),

      getVersion () {
        if (!this.isLogged) return;
        try {
          this.$axios.get('/system/version').then(res => {
            this.version = res.data.data;
          })
        } catch (error) {
          this.version = '1.0.0E';
        }
      },

      toCreateSupplier () {
        this.$router.push({ path: '/suppliers', query: { creating: true } });
      }
    },
  }

</script>

<style scoped>
.v-list-group__items .v-list-item {
  padding-inline-start: 16px !important;
}

</style>

<style lang="scss" scoped>

</style>