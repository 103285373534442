<template>
  <v-form validate-on="submit" ref="form" @submit.prevent="saveItem" class="px-4">
    <v-row>
      <v-col cols="12" lg="4">
        <v-text-field 
            v-model="form.name" 
            :rules="[rules.required, maxChar(1024)]" 
            :loading="loading" 
            :disabled="loading" 
            label="Nome" 
            density="compact" 
            variant="solo-filled"
        ></v-text-field>
      </v-col>

      <v-col cols="12" lg="4">
        <v-text-field v-model="form.cnpj"
            :rules="[rules.required, maxChar(18)]" 
            :loading="loading" 
            :disabled="loading" 
            @blur="fetchCNPJ()" 
            :error-messages="getCPNJ ? cnpjError : ''" 
            validate-on-blur 
            label="CNPJ" 
            density="compact" 
            variant="solo-filled"
            v-mask="['##.###.###/####-##']"
        ></v-text-field>
      </v-col>

      <v-col cols="12" lg="4">
        <v-text-field 
            v-model="form.email" 
            :rules="[rules.required, rules.email, maxChar(1024)]" 
            :loading="loading" 
            :disabled="loading" 
            label="E-mail" 
            density="compact" 
            variant="solo-filled"
        ></v-text-field>
      </v-col>

      <v-col cols="12" lg="4">
        <v-text-field
            v-model="form.phone"
            :rules="[rules.required, maxChar(25)]"
            :loading="loading" 
            :disabled="loading" 
            label="Telefone" 
            density="compact" 
            variant="solo-filled"
            v-mask="['(##) ####-#####']"
        ></v-text-field>
      </v-col>

      <v-col cols="12" lg="4">
        <v-text-field 
          v-model="form.mobile"
          :rules="[maxChar(25)]" 
          :loading="loading" 
          :disabled="loading" 
          label="Celular" 
          density="compact" 
          variant="solo-filled"
          v-mask="['(##) #####-####']"
        ></v-text-field>
      </v-col>

      <v-col cols="12" lg="4">
        <v-text-field v-model="form.atuation_area"
          :rules="[rules.required, maxChar(1024)]" 
          :loading="loading" 
          :disabled="loading" 
          label="Área de atuação" 
          density="compact" 
          variant="solo-filled"
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <h2>Endereço</h2>
      </v-col>

      <v-col cols="12" lg="3">
        <v-text-field
          v-model="form.address_zipcode" 
          :rules="[rules.required, maxChar(9)]" 
          :loading="loading" 
          :disabled="loading" 
          @blur="getAddress()" 
          :error-messages="cepError" 
          validate-on-blur 
          label="CEP" 
          density="compact" 
          variant="solo-filled"
          v-mask="['#####-###']"
        ></v-text-field>
      </v-col>

      <v-col cols="12" lg="3">
        <v-text-field v-model="form.address_number" :rules="[rules.required, maxChar(10)]" :loading="loading" :disabled="loading" label="Número" density="compact" variant="solo-filled"></v-text-field>
      </v-col>

      <v-col cols="12" lg="3">
        <v-text-field v-model="form.address_complement" :rules="[maxChar(1024)]" :loading="loading" :disabled="loading" label="Complemento" density="compact" variant="solo-filled"></v-text-field>
      </v-col>

      <v-col cols="12" lg="3">
        <v-text-field v-model="form.address_neighborhood" :rules="[rules.required, maxChar(1024)]" :loading="loading" :disabled="loading" label="Bairro" density="compact" variant="solo-filled"></v-text-field>
      </v-col>

      <v-col cols="12" lg="4">
        <v-text-field v-model="form.address" :rules="[rules.required, maxChar(1024)]" :loading="loading" :disabled="loading" label="Endereço" density="compact" variant="solo-filled"></v-text-field>
      </v-col>

      <v-col cols="12" lg="4">
        <v-text-field v-model="form.address_city" :rules="[]" :loading="loading" :disabled="loading" label="Cidade" density="compact" variant="solo-filled"></v-text-field>
      </v-col>

      <v-col cols="12" lg="4">
        <v-text-field v-model="form.address_state" :rules="[rules.required, maxChar(2)]" :loading="loading" :disabled="loading" label="Estado" density="compact" variant="solo-filled"></v-text-field>
      </v-col>

      <!-- <v-col cols="12" lg="6">
        <v-file-input v-model="form.logo" label="Logo" density="compact" variant="solo-filled"></v-file-input>
      </v-col> -->
      <v-col cols="12" class="d-flex py-5">
        <v-spacer></v-spacer>
        <v-btn variant="text" color="error" class="mr-4" @click="closeModal" v-if="close" :loading="loading"> Cancelar </v-btn>
        <v-btn color="primary" type="submit" :loading="loading"> Salvar </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
  import { constants } from '@/mixins/constants';
  export default {
    mixins: [constants],
    props: {
      close: {
        type: Boolean,
        default: false,
      },
      getCPNJ: {
        type: Boolean,
        default: false,
      },
      modelValue: {
        type: Object,
        default: () => ({
          name: '',
          cnpj: '',
          email: '',
          phone: '',
          mobile: '',
          address: '',
          address_number: '',
          address_complement: '',
          address_city: '',
          address_state: '',
          address_zipcode: '',
          address_neighborhood: '',
          atuation_area: '',
          logo: '',
        })
      },
      loading: {
        type: Boolean,
        default: false,
      }
    },

    data() {
      return {
        rules: {
          required: value => !!value || 'Campo obrigatório',
          email: value => /.+@.+\..+/.test(value) || 'E-mail inválido',
          min: v => v.length >= 6 || 'Mínimo de 6 caracteres',
          cnpj: value => value.length === 14 || 'CNPJ inválido',
          phone: value => value.length === 10 || 'Telefone inválido',
          mobile: value => value.length === 11 || 'Celular inválido',
          zipcode: value => value.length === 8 || 'CEP inválido',
        },

        cepError: '',
        cnpjError: '',
      }
    },
    
    computed: {
      form: {
        get() {
          return this.modelValue
        },
        set(value) {
          this.$emit('update:modelValue', value)
        }
      }
    },

    methods: {
      maxChar(maxAmount) { 
        return v => !v || v.length <= maxAmount || `Máximo de ${maxAmount} caracteres`;
      },

      async getAddress () {
        if (this.form.address_zipcode.length < 9) return this.cepError = 'CEP inválido'
        try {
          const response = (await this.$axios.get(`https://viacep.com.br/ws/${this.form.address_zipcode}/json/`)).data
          const { logradouro, bairro, localidade, uf } = response;

          if (!logradouro) throw new Error('CEP inválido')

          this.form.address = logradouro
          this.form.address_neighborhood = bairro
          this.form.address_city = localidade
          this.form.address_state = uf

          this.cepError = '';
        } catch (error) {
          this.form.address = null
          this.form.address_neighborhood = null
          this.form.address_city = null
          this.form.address_state = null

          this.cepError = 'CEP inválido'
        }
      },

      async fetchCNPJ () {
        if (!this.getCPNJ) return;
        if (this.form.cnpj.length < 14) return this.cnpjError = 'CNPJ inválido'

        const cnpj = this.$toNumeric(this.form.cnpj);
        const url = `https://www.receitaws.com.br/v1/cnpj/${cnpj}`;

        const currentOrigin = new URL(process.env.VUE_APP_API_URL).hostname;
        if (['localhost', '127.0.0.1'].includes(currentOrigin)) {
          return this.cnpjError = 'Serviço indisponível (CORS)';
        }

        const [response, error] = await this.handleAsyncOperation(this.$axios.get(url));        
        if (!error && response.data.status === 'OK') {
          const { status, message, nome, email, telefone, cep, logradouro, numero, complemento, bairro, localidade, uf } = response.data;
          if (status === 'ERROR') return this.cnpjError = message;

          this.form.name = nome;
          this.form.email = email;
          this.form.phone = telefone;
          this.form.address_zipcode = cep;
          this.form.address = logradouro;
          this.form.address_number = numero;
          this.form.address_complement = complemento;
          this.form.address_neighborhood = bairro;
          this.form.address_city = localidade;
          this.form.address_state = uf;

          this.cnpjError = '';
        } else {
          // cors error handling for development environment
          const { status } = error && error.response || {};
          if (status === 429) {
            return this.cnpjError = 'Limite de requisições atingido, tente novamente mais tarde.';
          }

          this.cnpjError = 'CNPJ inválido'
        }
      },

      async saveItem () {
        const { valid } = await this.$refs.form.validate();
        if (!valid) return

        this.$emit('on:submit');
      },

      closeModal () {
        this.$refs.form.resetValidation();
        this.$emit('update:modelValue', {});
        this.$emit('on:cancel');
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>