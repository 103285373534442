<template>
  <v-sheet>
    <v-autocomplete
      v-model="client"
      :items="clients"
      :custom-filter="customFilter"
      label="Localizar cliente"
      item-title="name"
      item-value="[id, name, register]"
      outlined
      dense
      clearable
      return-object
      variant="solo-filled"
      class="mb-2"
    >
      <template v-slot:item="{ props, item }">
        <v-list-item
          v-bind="props"
          three-line
        >
            <v-list-item-subtitle>
              {{ item.raw.register_type == 2 ? 'CNPJ' : 'CPF' }}: {{ formatNumber(item.raw.register, item.raw.register_type == 2 ? 'cnpj' : 'cpf') }}
            </v-list-item-subtitle>

            <v-list-item-subtitle>
              Telefone: {{ formatNumber(item.raw.phone, 'phone') }}
            </v-list-item-subtitle>
        </v-list-item>
    </template>

    </v-autocomplete>

    <div class="client-info" v-if="client">
      <v-row>
        <!-- <v-col cols="12" lg="12">
          <v-text-field
            v-model="client.name"
            label="Cliente"
            outlined
            readonly
            disabled
            variant="solo-filled"
          ></v-text-field>
        </v-col> -->

        <v-col cols="12" lg="8" class="py-0">
          <v-text-field
            v-model="client.address"
            label="Endereço"
            outlined
            dense
            variant="solo-filled"
          ></v-text-field>
        </v-col>

        <v-col cols="12" lg="4" class="py-0">
          <v-text-field
            v-model="client.neighborhood"
            label="Bairro"
            outlined
            dense
            variant="solo-filled"
          ></v-text-field>
        </v-col>

        <v-col cols="12" lg="4" class="py-0">
          <v-text-field
            v-model="client.number"
            label="Número"
            outlined
            dense
            variant="solo-filled"
          ></v-text-field>
        </v-col>

        <v-col cols="12" lg="4" class="py-0">
          <v-text-field
            v-model="client.complement"
            label="Complemento"
            outlined
            dense
            variant="solo-filled"
          ></v-text-field>
        </v-col>

        <v-col cols="12" lg="4" class="py-0">
          <v-text-field
            v-model="client.zipcode"
            label="CEP"
            outlined
            variant="solo-filled"
            v-mask="'#####-###'"
            :error-messages="cepError" 
            validate-on-blur 
            @blur="getAddress()" 
          ></v-text-field>
        </v-col>

        <v-col cols="12" lg="4" class="py-0">
          <v-text-field
            v-model="client.register"
            label="CPF/CNPJ"
            outlined
            dense
            readonly
            disabled
            variant="solo-filled"
            v-mask="client.register_type == 2 ? '##.###.###/####-##' : '###.###.###-##'"
          ></v-text-field>
        </v-col>

        <v-col cols="12" lg="4" class="py-0">
          <v-text-field
            v-model="client.email"
            label="E-mail"
            outlined
            dense
            readonly
            disabled
            variant="solo-filled"
          ></v-text-field>
        </v-col>

        <v-col cols="12" lg="4" class="py-0">
          <v-text-field
            v-model="client.phone"
            label="Telefone"
            outlined
            dense
            readonly
            disabled
            variant="solo-filled"
            format="phone"
            v-mask="'(##) #####-####'"
          ></v-text-field>
        </v-col>

      </v-row>
    </div>
  </v-sheet>

</template>

<script>
  export default {
    props: {
      modelValue: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        clients: [],
        cepError: '',
      }
    },

    async mounted() {
      this.getClientsList();
    },

    computed: {
      client: {
        get() {
          return this.modelValue;
        },
        set(value) {
          this.$emit('update:modelValue', value);
        },
      },
    },

    methods: {
      customFilter (itemTitle, queryText, item) {
        const clientName = item.raw.name.toLowerCase()
        const cpfNumber = this.$stringToNumber(item.raw.register.toString()).toString()
        const phone = item.raw?.phone != null ? this.$stringToNumber(item.raw.phone.toString()).toString() : '';
        const searchText = queryText.toLowerCase()
        return clientName.indexOf(searchText) > -1 || cpfNumber.indexOf(searchText) > -1 || phone.indexOf(searchText) > -1
      },

      async getClientsList () {
        const [response, error] = await this.$handleAsync(this.$axios.get('/clients'));
        if (!error) this.clients = response.data.data;
      },

      formatNumber(number, type = "phone") {
        if (!number) return;
        number = number.toString();

        const formats = {
          cep: `${number.replace(/(\d{5})(\d{3})/, "$1-$2")}`,
          phone: `${number.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3")}`,
          cpf: `${number.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")}`,
          cnpj: `${number.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")}`,
        }

        return formats[type] || number;
      },

      async getAddress () {
        try {
          const response = (await this.$axios.get(`https://viacep.com.br/ws/${this.client.zipcode}/json/`)).data
          const { logradouro, bairro, localidade, uf } = response;

          if (!logradouro) throw new Error('CEP inválido')

          this.client.address = logradouro
          this.client.neighborhood = bairro
          this.client.city = localidade
          this.client.state = uf

          this.cepError = '';
            
        } catch (error) {
          this.cepError = 'CEP inválido'
        }
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>