<template>
    <ProductList />
</template>

<script>
    import ProductList from '@/components/Base/panel/ProductList.vue';
    export default {
        name: 'page-products',
        components: { ProductList },
    }
</script>

<style lang="scss" scoped>

</style>