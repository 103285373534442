<template>
    <v-card class="pa-5 d-flex flex-column">
        <v-row class="mb-4" align="center">
            <v-col cols="12" lg="4">
                <h2>Unidades de Medida</h2>
            </v-col>

            <v-col cols="12" lg="4">
                <v-text-field
                    :loading="unit.loading"
                    :disabled="unit.loading"
                    v-model="unit.search"
                    density="compact"
                    variant="solo-filled"
                    label="Pesquisar por nome"
                    append-inner-icon="mdi-magnify"
                    single-line
                    hide-details
                ></v-text-field>
            </v-col>

            <v-col cols="12" lg="3" class="text-right">
                <v-btn color="primary" @click="showModal">
                    Nova Unidade
                </v-btn>
            </v-col>
        </v-row>

        <ListCRUD :headers="unit.headers" :items="unit.items" :loading="unit.loading" :search="unit.search" @action:edit="showModal" @action:delete="deleteUnit" @action:refresh="fetchMeasureUnits" />

        <v-dialog v-model="unitModal" max-width="500px">
            <v-card class="pa-2">
                <v-card-title class="px-0">
                    <v-container>
                        <span class="headline">{{ unitModalTitle }}</span>
                    </v-container>
                </v-card-title>
                <v-form ref="form" class="px-4" fast-fail @submit.prevent>
                    <v-row>
                        <v-col cols="12" lg="12" class="pb-0">
                            <v-text-field variant="solo-filled" :rules="unit.rules.name" v-model="unitModalData.name" label="Nome" required :disabled="saveLoading"></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="12" class="pb-0">
                            <v-text-field variant="solo-filled" :rules="unit.rules.symbol" v-model="unitModalData.symbol" label="Sigla" required :disabled="saveLoading"></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="12" class="pb-0">
                            <v-textarea variant="solo-filled" :rules="unit.rules.description" v-model="unitModalData.description" label="Descrição" :disabled="saveLoading"></v-textarea>   
                        </v-col>
                    </v-row>
                </v-form>
                <v-card-actions class="px-4">
                    <v-spacer></v-spacer>
                    <v-btn color="darken-1" text @click="clearUnit" :disabled="saveLoading"> Cancelar </v-btn>
                    <v-btn color="green" @click="saveUnit" :disabled="saveLoading"> Salvar </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-card>  
</template>

<script>
import Swal from 'sweetalert2';
import ListCRUD from '../ListCRUD.vue'
export default {
    components: { ListCRUD },
    data() {
        return {
            unit: {
                items: [],
                search: '',
                loading: true,
                headers: [
                    { title: 'ID', key: 'id' },
                    { title: 'Nome', key: 'name' },
                    { title: 'Sigla', key: 'symbol' },
                    { title: 'Descrição', key: 'description' },
                    { title: 'Ações', key: 'actions', sortable: false },
                ],

                // add rules for validation in the future
                rules: {
                    name: [
                        v => !!v || 'Nome é obrigatório',
                        v => v.length <= 255 || 'Nome deve ter no máximo 255 caracteres',
                    ],
                    symbol: [
                        v => !!v || 'Sigla é obrigatório',
                        v => v.length <= 255 || 'Sigla deve ter no máximo 255 caracteres',
                    ],
                    description: [
                        v => v ? v.length <= 255 : true || 'Descrição deve ter no máximo 255 caracteres',
                    ]
                }
            },

            unitModal: false,
            saveLoading: false,
            unitModalTitle: 'Nova Unidade de Medida',

            valid: true,

            unitModalData: {
                id: null,
                name: '',
                symbol: '',
                description: '',
            }
        }
    },

    async mounted () {
        await this.fetchMeasureUnits();
    },

    methods: {
        clearUnit() {
            this.unitModal = false;
            this.unitModalData = {
                id: null,
                name: '',
                symbol: '',
                description: '',
            }

            this.$refs.form.resetValidation();
        },

        showModal(item) {
            this.unitModal = true;

            if (item && !(item instanceof PointerEvent)) {
                this.unitModalTitle = 'Editando Unidade de Medida';
                Object.assign(this.unitModalData, item);
            } else {
                this.unitModalTitle = 'Nova Unidade de Medida';
                this.unitModalData = {
                    id: null,
                    name: '',
                    symbol: '',
                    description: '',
                }
            }
        },

        async fetchMeasureUnits() {
            this.unit.loading = true;

            const [response, error] = await this.$handleAsync(this.$axios.get('/units'));
            if (!error && response.data.success) this.unit.items = response.data.data;

            this.unit.loading = false;
        },

        async saveUnit() {
            const { valid } = await this.$refs.form.validate() 
            if (!valid) return;

            this.saveLoading = true;

            try {
                if (this.unitModalData.id) {
                    await this.$axios.put(`/unit/${this.unitModalData.id}`, this.unitModalData);
                } else {
                    await this.$axios.post('/unit', this.unitModalData);
                }

                this.clearUnit();

                this.$notify({
                    title: 'Sucesso!',
                    text: 'Ação realizada com sucesso!',
                    icon: 'success',
                })

                await this.fetchMeasureUnits();
            } catch (error) {
                console.log(error);
            }

            this.saveLoading = false;
        },

        async deleteUnit(item) {

            // adicionar $swal para confirmar a exclusão

            Swal.fire({
                title: 'Tem certeza?',
                text: "Você não poderá reverter isso!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sim, deletar!',
                cancelButtonText: 'Não, cancelar!',
                reverseButtons: true,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await this.$axios.delete(`/unit/${item.id}`);
                        await this.fetchMeasureUnits();
                    } catch (error) {
                        console.log(error);
                    }

                    this.$notify({
                        title: 'Sucesso!',
                        text: 'Ação realizada com sucesso!',
                        icon: 'success',
                    })
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>

</style>