export const constants = {
    data() {
        return {
            rules: {
                required: value => !!value || 'Campo obrigatório',
                email: value => /.+@.+\..+/.test(value) || 'E-mail inválido',
                min: value => value.length >= 6 || 'Mínimo de 6 caracteres',
                cnpj: value => value.length === 14 || 'CNPJ inválido',
                phone: value => value.length === 10 || 'Telefone inválido',
                mobile: value => value.length === 11 || 'Celular inválido',
                zipcode: value => value.length === 8 || 'CEP inválido',
            },
        }
    },
    computed: {
        saleStatus() {
            return {
                RA: { text: 'Rascunho', color: 'orange' },
                CA: { text: 'Cancelado', color: 'red' },
                CO: { text: 'Confirmado', color: 'green' },
                AN: { text: 'Anulado', color: 'red' },
            }
        },
    },

    methods: {
        formatCurrency(value) {
            return new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
            }).format(value);
        },

        stringToNumber (value) {
            return parseInt(value && value.match(/\d+/g) ? value.match(/[\d,]/gm).join('').replace(',', '.') : '0');
        },

        valueRules(value) {
            if (!value) return true;
            const number = value.replace(/\D/g, ""); // remove all non numeric characters
            return number > 0 || "Valor deve ser maior que 0";
        },

        formatDate(value) {
            return new Intl.DateTimeFormat('pt-BR').format(new Date(value));
        },

        async handleAsyncOperation(asyncOperation) {
			try {
				const response = await asyncOperation;
				return [response, null];
			} catch (error) {
				return [null, error];
			}
		}
    },
}