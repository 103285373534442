import { createRouter, createWebHistory } from 'vue-router';
import { checkAuth, isLogged, doLogout } from './api/security/navigationGuards.js';


/**
 * Rotas de autenticação e controle de usuários
 */
import LoginComponent from './views/UserLogin.vue';
import LogoutComponent from './views/UserLogout.vue';
import HomeComponent from './views/Home.vue';
import SettingsView from './views/SettingsPage.vue';
import SalesList from './views/sales/SalesList.vue';
import SalesDocument from './views/sales/SalesDoc.vue';
import ProductsView from './views/ProductsView.vue';
import CategoriesView from '@/components/Base/panel/CategoryList.vue';
import UnitsView from './views/UnitsView.vue';
import CostView from './views/CostView.vue';

import StockItems from './views/stock/StockItems.vue';
import StockDocument from './views/stock/StockDocument.vue';

import clientsList from './components/Base/panel/ClientList.vue';

import SuppliersView from './views/suppliers/ListAndCreate.vue';

const routes = [
  { path: '/', component: HomeComponent, beforeEnter: checkAuth },
  { path: '/login', component: LoginComponent, beforeEnter: isLogged },
  { path: '/logout', component: LogoutComponent, beforeEnter: doLogout },
  { path: '/settings', component: SettingsView, beforeEnter: checkAuth },
  
  { path: '/stocks', component: StockItems, beforeEnter: checkAuth },
  { path: '/stock/', component: StockItems, beforeEnter: checkAuth},
  { path: '/stock/:id/', component: StockDocument, beforeEnter: checkAuth },
  
  { path: '/sales', component: SalesList, beforeEnter: checkAuth },
  { path: '/sale/', component: SalesList, beforeEnter: checkAuth},
  { path: '/sale/:slug/', component: SalesDocument, beforeEnter: checkAuth },

  { path: '/products', component: ProductsView, beforeEnter: checkAuth },
  { path: '/categories', component: CategoriesView, beforeEnter: checkAuth },

  { path: '/units', component: UnitsView, beforeEnter: checkAuth },

  { path: '/costs', component: CostView, beforeEnter: checkAuth },

  { path: '/customers', component: clientsList, beforeEnter: checkAuth },
  
  // suppliers
  { path: '/suppliers', component: SuppliersView, beforeEnter: checkAuth },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;