<template>
    <section class="mt-5">
        <h3> Total: <span :class="pendingTotalFixed === 0 ? 'text--error' : 'text--success'"> {{ $money(total) }} </span> </h3>
        <h4> Desconto: <span class="text--error"> {{ formattedDiscount }} </span> </h4>
        <h2 class="mb-2"> Total pendente: <span :class="pendingTotalFixed != 0 ? 'text--error' : 'text--success'"> {{ $money(pendingTotalFixed) }} </span> </h2>

        <v-row v-for="(payment, idx) in payments" :key="`payment-${idx}`">
            <v-col cols="12" lg="6">
                <v-autocomplete
                    v-model="notes[idx].payment_id"
                    :items="paymentList"
                    item-title="name"
                    item-value="id"
                    label="Forma de Pagamento"
                    variant="solo-filled"
                    dense
                    hide-details=""
                    :rules="[rules.required]"
                />
            </v-col>

            <v-col cols="12" lg="5">
                <v-text-field
                    v-model.lazy="notes[idx].value"
                    label="Valor em R$"
                    outlined
                    dense
                    hide-details=""
                    variant="solo-filled"
                    :rules="[rules.required]"
                    v-money="{decimalCount: 2, precision: 2, allowNegative: false, prefix: 'R$ ', thousands: '.', decimal: ','}"
                />
            </v-col>

            <v-col cols="12" lg="1" class="d-flex align-center">
                <v-btn
                    size="small"
                    color="success"
                    icon="mdi-plus"
                    variant="plain"
                    @click="addPaymentMethod({ payment_id: paymentList[0].id, value: 'R$ 0,00' })"
                >  </v-btn> 

                <v-btn
                    size="small"
                    color="error"
                    icon="mdi-delete"
                    variant="plain"
                    :disabled="payments.length === 1"
                    @click="notes.splice(idx, 1)"
                > </v-btn>
            </v-col>
        </v-row>

    </section>
</template>

<script>
    import { constants } from '@/mixins/constants';
    export default {
        mixins: [constants],
        
        props: {
            payments: {
                type: Array,
                default: () => []
            },
            paymentList: {
                type: Array,
                default: () => []
            },
            pendingTotal: {
                type: [Number, String],
                default: 0
            },
            total: {
                type: String,
                default: ''
            },
            discountValue: {
                type: String,
                default: ''
            }
        },

        computed: {
            notes: {
                get() {
                    return this.payments;
                },
                set(value) {
                    this.$emit('update:payments', value);
                }
            },

            pendingTotalFixed () {
                const pendingTotal = this.pendingTotal.toFixed(2);
                if (pendingTotal === '-0.00') return '0.00';
                return pendingTotal;
            },
            formattedDiscount () {
                if (this.discountValue > 0) {
                    return '-' + this.$money(this.discountValue);
                } else {
                    return this.$money(this.discountValue);
                }
            }
        },

        methods: {
            addPaymentMethod(payment) {
                this.notes.push(payment);
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>