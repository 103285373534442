import router from "../router";
import { createStore } from "vuex";

import UserAPI from '@/api/user.js';
import ProductAPI from '@/api/product.js';

import Swal from "sweetalert2";

export default createStore({
    state: {
        user: null,
        isLogged: false,
        
        units: [],
        products: [],
        suppliers: [],
        categories: [],
        productAlerts: [],

        clients: [],

        // document types
        stock_types: [],
        sales_types: [],
    },
    
    mutations: {
        setLogged(state, payload) {
            state.isLogged = payload;
        },

        doLogout(state) {
            state.isLogged = false;
            localStorage.removeItem("_token");

            // redirect to login
            router.push('/login');
        },

        setUser(state, payload) {
            state.user = payload;
        },

        setAlerts(state, payload) {
            state.productAlerts = payload;
        },

        setProducts(state, payload) {
            state.products = payload;
        },

        setSuppliers(state, payload) {
            state.suppliers = payload;
        },

        setDocumentTypes(state, payload) {
            const { stock_types, sales_types } = payload || {};
    
            state.stock_types = stock_types;
            state.sales_types = sales_types;
        },

        setClients(state, payload) {
            state.clients = payload;
        }
    },

    actions: {
        async doLogin({ commit }, payload) {
            return UserAPI.login(payload.email, payload.password)
            .then(response => {
                console.log(response);
                
                // save token
                localStorage.setItem("_token", response.data._token);

                // set user data
                commit("setLogged", true);
            })
            .catch(err => {
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: "Acesso negado",
                    text: err.response.data.error,
                    position: 'top-end',
                })
            });
        },

        async doLogout({ commit }) {            
            await UserAPI.logout();
            commit("doLogout");
        },

        async getAlerts({ commit }) {
            const [response, error] = await ProductAPI.getAlerts();

            if (!error) {
                commit("setAlerts", response.data);
            }
        },

        async getProducts ({ commit }) {
            const [response, error] = await ProductAPI.fetch({ endpoint: '/products', method: 'get' });

            if (!error && response.data.success) {
                commit("setProducts", response.data.data);
            }
        },

        async getSuppliers ({ commit }) {
            const [response, error] = await ProductAPI.fetch({ endpoint: '/suppliers', method: 'get' });

            if (!error && response.data.success) {
                commit("setSuppliers", response.data.data);
            }
        },

        async getDocumentTypes ({ commit }) {
            const [response, error] = await ProductAPI.fetch({ endpoint: '/document/type/list', method: 'get' });

            if (!error && response.data.success) {
                commit("setDocumentTypes", response.data.data);
            }
        },

        async getClients ({ commit }) {
            const [response, error] = await ProductAPI.fetch({ endpoint: '/clients', method: 'get' });

            if (!error && response.data.success) {
                commit("setClients", response.data.data);
            }
        }
    },
});