<template>
  <v-dialog max-width="clamp(50%, 500px, 100%)" persistent>
    <v-card class="pa-2">
      <v-card-title class="px-0">
        <v-container>
          <span class="headline"> Nova Categoria </span>
        </v-container>
      </v-card-title>
      <v-form ref="formModal" class="px-4" fast-fail>
        <v-row>
          <v-col cols="12" lg="12" class="pb-0">
            <v-text-field
              variant="solo-filled"
              :rules="item.rules.name"
              v-model="modalData.name"
              label="Nome"
              required
              :disabled="saveLoading"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="12" class="pb-0">
            <v-textarea
              variant="solo-filled"
              v-model="modalData.description"
              label="Descrição"
              :disabled="saveLoading"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-form>
      <v-card-actions class="px-4">
        <v-spacer></v-spacer>
        <v-btn color="darken-1" text @click="$emit('close')" :disabled="saveLoading" > Cancelar </v-btn>
        <v-btn color="green" @click="saveItem();" :disabled="saveLoading"> Salvar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { constants } from '@/mixins/constants';
export default {
  mixins: [constants],
  props: {
    value: Boolean,
    itemObject: Object,
  },
  data() {
    return {
      item: {
        rules: {
          name: [
            (v) => !!v || "Nome é obrigatório",
            (v) => v.length <= 255 || "Nome deve ter no máximo 255 caracteres",
          ],
          description: [
            (v) =>
              v.length <= 255 || "Descrição deve ter no máximo 255 caracteres",
          ],
          required: [(v) => !!v || "Este campo é obrigatório"],
        },
      },
      saveLoading: false,
      modalData: {
        id: null,
        name: "",
        description: "",
      },
    };
  },

  methods: {
    clearItem(isClosing = false) {
      this.modalData = {
        id: null,
        name: "",
        description: "",
      };

      if (isClosing) {
        this.$emit("refresh");
        this.$emit("close", false);
      }
    },

    maxChar(maxAmount) { 
      return v => v.length <= maxAmount || `Máximo de ${maxAmount} caracteres`;
    },

    async saveItem() {
      const { valid } = await this.$refs.formModal.validate();
      if (!valid) return;

      try {
        this.saveLoading = true
        
        const [response, error] = await this.handleAsyncOperation(this.$axios.post("/category", this.modalData));

        if (error) {
          const snack = {
            icon: "error",
            message: "Erro ao cadastrar categoria!",
          };

          this.$notify(snack);
          return;
        }

        const { success } = response.data;
        if (success) {
          this.$notify({
            title: 'Sucesso!',
            text: 'Categoria cadastrada com sucesso!',
            icon: 'success',
          })
        }

        this.clearItem(true);
      } catch (error) {
        console.log(error);
      }

      this.saveLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
