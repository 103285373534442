<template>
  <div>
    <v-stepper
      v-model="step"
      editable
      prev-text="Voltar"
      next-text="Próximo"
      alt-labels
      :hide-actions="step > 4"
      :items="items"
    >
      <template v-slot:[`item.1`]>
        <v-row>
          <v-col cols="12" lg="12">
            <!-- <item-header :company="company" v-if="Object.keys(company).length > 0" /> -->
            
            <h3 class="text-h6"> {{ items[0].description }} </h3>
            <ItemDate v-model="note.date" />
          </v-col>
        </v-row>
      </template>

      <template v-slot:[`item.2`]>

        <v-row>
          <v-col cols="12" class="d-flex align-center justify-space-between mb-4">
            <h3 class="text-h6"> {{ items[1].description }} </h3>
            
            <v-btn @click="clientModal = !clientModal" color="primary">
              Adicionar um cliente
            </v-btn>
            
            <v-dialog v-model="clientModal" max-width="clamp(50%, 500px, 100%)" persistent>
              <client-modal :client="clientModalData" :editingIndex="editingIndex" @close="closeClientModal" @refresh="getClients" />
            </v-dialog>

          </v-col>
        </v-row>
              
        <ItemClients v-model="client" ref="clients" />
      </template>

      <template v-slot:[`item.3`]>
        <v-row>
          <v-col cols="12" class="d-flex align-center justify-space-between mb-4">
            <h3 class="text-h6"> {{ items[2].description }} </h3>
            
            <v-btn @click="productModal = !productModal" color="primary">
              Adicionar um produto
            </v-btn>
          </v-col>
        </v-row>

        <ItemProducts
          ref="itemProducts"
          :note="note"
          :total="subtotal"
          :discountValue="discountAmount"
          @add-product="addProductToNote"
          @remove-product="removeProductFromNote"
        />

      </template>

      <template v-slot:[`item.4`]>     

        <v-row>
          <v-col cols="12">
            <h5> Desconto </h5>

            <v-alert type="info" variant="tonal" class="my-4">
              <template v-slot:icon>
                <v-icon>mdi-information</v-icon>
              </template>

              <span> O desconto global é aplicado sobre o valor total da venda. </span>
            </v-alert>

            <v-text-field
              v-model.lazy="note.discount_global"
              v-money="{decimalCount: 0, precision: 2, allowNegative: false, prefix: '', suffix: '%', thousands: '.', decimal: ','}"
              label="Desconto"
              required
              variant="solo-filled"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <h4> {{ items[3].description }} </h4>
            <ItemPaymentList :payments="note.payments" :pendingTotal="pendingTotal" :discountValue="discountAmount" :total="staticTotal" :payment-list="paymentList" />
          </v-col>
        </v-row>
      </template>

      <template v-slot:[`item.5`]>
        <h2 class="mb-4">{{ items[4].description }}</h2>

        <section class="section">
          <h4> Dados da venda </h4>
          <ItemDate v-model="note.date" class="mb-4" />
        </section>

        <section class="section">
          <h4> Dados do cliente </h4>

          <ItemClients v-model="client" :disabled="true" ref="clients" />
        </section>

        <section class="section">
          <h4> Produtos </h4>

          <ItemProducts
            :note="note"
            :total="subtotal"
            @add-product="addProductToNote"
            @remove-product="removeProductFromNote"
            :discountValue="discountAmount"
            ref="itemProducts"
          />
        </section>

        <section class="section">
          <v-row>
            <v-col cols="12">
              <h5> Desconto </h5>

              <v-alert type="info" variant="tonal" class="my-4">
                <template v-slot:icon>
                  <v-icon>mdi-information</v-icon>
                </template>

                <span> O desconto global é aplicado sobre o valor total da venda. </span>
              </v-alert>

              <v-text-field
                v-model.lazy="note.discount_global"
                v-money="{decimalCount: 0, precision: 2, allowNegative: false, prefix: '', suffix: '%', thousands: '.', decimal: ','}"
                label="Desconto"
                required
                variant="solo-filled"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <h4> {{ items[3].description }} </h4>
              <ItemPaymentList :payments="note.payments" :pendingTotal="pendingTotal" :discountValue="discountAmount" :total="staticTotal" :payment-list="paymentList" />
            </v-col>
          </v-row>
        </section>

        <v-alert v-if="!client" type="error" variant="tonal" class="my-4">
          <template v-slot:icon>
            <v-icon>mdi-alert</v-icon>
          </template>

          <span> Selecione um cliente para continuar </span>
        </v-alert>

        <v-alert v-if="pendingTotal != 0" type="error" variant="tonal" class="my-4">
          <template v-slot:icon>
            <v-icon>mdi-alert</v-icon>
          </template>

          <span> Atenção! O valor total da venda ainda não foi pago. 
            <span class="text--error"> {{ $money(pendingTotal) }} </span>
          </span>
        </v-alert>

        <v-alert v-if="note.products.length === 0" type="error" variant="tonal" class="my-4">
          <template v-slot:icon>
            <v-icon>mdi-alert</v-icon>
          </template>

          <span> Adicione pelo menos um produto para continuar </span>
        </v-alert>
        
        <v-row>
          <v-col cols="12" class="d-flex justify-end">
            <v-btn
              color="primary"
              @click="submitDocument"
              :disabled="isLastStepDisabled"
            >
              Finalizar
            </v-btn>
          </v-col>

        </v-row>
      </template>
    </v-stepper>

    <v-dialog v-model="productModal" persistent max-width="clamp(50%, 500px, 100%)">
      <product-modal
        :product="productModalData"
        @close="closeProductModal"
        @refresh="getProducts"
      />
    </v-dialog>
  </div>
</template>

<script>
import { constants } from '@/mixins/constants';
import ItemDate from '@/components/Documents/ItemDate.vue';
// import ItemHeader from '@/components/Documents/ItemHeader.vue';
import ItemClients from '@/components/Documents/ItemClients.vue';
import ItemProducts from '@/components/Documents/ItemProducts.vue';
import ItemPaymentList from '@/components/Documents/ItemPayments.vue';
import ClientModal from '@/components/Base/modal/clientModal.vue';
import ProductModal from '@/components/Base/modal/productModal.vue';	

export default {
  mixins: [constants],
  components: {
    ItemDate,
    // ItemHeader,
    ItemClients,
    ItemProducts,
    ItemPaymentList,
    ClientModal,
    ProductModal,
  },
  data() {
    return {
      step: 1,

      client: null,

      note: {
        state: "RA",
        client_id: null,
        client_name: null,
        warehouse_id: 1,
        document_type_id: 1,
        date: new Date().toISOString().substring(0, 19).replace('T', ' '),
        lines: [],
        total: 0,
        payments: [],
        products: [],
      },

      company: {},
      paymentList: [],

      items: [
        { step: 1, title: 'Data da venda', description: "Quando essa venda foi realizada?" },
        { step: 2, title: 'Cliente', description: "Busque por CPF, CNPJ, Telefone ou Nome do cliente" },
        { step: 3, title: 'Produtos', description: "Busque e adicione um novo produto" },
        { step: 4, title: 'Pagamento', description: "Selecione a forma de pagamento" },
        { step: 5, title: 'Finalizar', description: "Confira os dados da nota, e finalize a nota." },
      ],

      productModal: false,
      productModalData: {},
      productDefault: {
        id: null,
        name: "",
        description: "",
        price_sale: 0,
        price_purchase: 0,
        profit_margin: 0,
        category_id: "",
        quantity: 0,
      },

      clientModal: false,
      clientModalData: {},
      clientDefault: {
        id: null,
        name: '',
        zipcode: '',
        address: '',
        neighborhood: '',
        number: '',
        register: '',
        complement: '',
        phone: '',
        email: '',
        condiction: '',
        observation: '',
      }
    }
  },

  async mounted () {
    Promise.all([
      this.getCompanyInfo(),
      this.getPaymentMethods(),
    ]);
  },

  computed: {
    subtotal () {
      return this.note.products.reduce((acc, line) => {
        return acc + this.$stringToNumber(line.total) || 0;
      }, 0);
    },

    pendingTotal() {
      const paymentTotals = (this.note.payments || []).reduce((acc, payment) => acc + this.$stringToNumber(payment.value), 0);  
      const subtotal = typeof this.subtotal === 'number' ? this.subtotal : 0;
      const discount = this.$stringToNumber(this.note.discount_global) ?? 0;

      const discountedAmount = ((discount / 100) * subtotal).toFixed(2);

      const pendingTotal = (subtotal - discountedAmount) - paymentTotals || 0;
      return pendingTotal;
    },

    staticTotal () {
      const subtotal = typeof this.subtotal === 'number' ? this.subtotal : 0;
      const discount = this.$stringToNumber(this.note.discount_global) || 0;

      const discountedAmount = ((discount / 100) * subtotal).toFixed(2);
      const total = (subtotal - discountedAmount).toFixed(2);

      return total;
    },

    discountAmount () {
      const subtotal = typeof this.subtotal === 'number' ? this.subtotal : 0;
      const discount = this.$stringToNumber(this.note.discount_global) || 0;

      return ((discount / 100) * subtotal).toFixed(2);
    },
    
    isLastStepDisabled () {
      return this.step < 3 || this.note.products.length === 0 || !this.client;
    },
  },

  watch: {
    step (val) {
      if (val > 3) {
        this.checkDocument();
      }
    },

    'note.discount_global' (val) {
      // if value to Number is greater than 100, set it to 100
      const value = this.$stringToNumber(val);
      if (value > 100) this.note.discount_global = '100.00%';
    }
  },

  methods: {
    getClients () {
      const clients = this.$refs.clients;
      clients.getClientsList();
    },

    async testDiscountGlobal() {
      const results = [];

      // iterar de 0.00 a 1.00 com incremento de 0.01
      for (let discount = 0; discount <= 100.0; discount += 0.01) {
        // Defina o desconto global no formato desejado
        this.note.discount_global = discount.toFixed(2);

        // atualize o primeiro item de this.note.payments
        const discountedAmount = (discount / 100) * this.subtotal;
        const updatedValue = (this.subtotal - discountedAmount).toFixed(2);
        this.note.payments[0].value = updatedValue;

        // verifique se o pendingTotal é igual à diferença entre o total dos pagamentos e o valor líquido da venda após o desconto
        const paymentTotals = (this.note.payments || []).reduce((acc, payment) => acc + this.$stringToNumber(payment.value), 0);  

        const testResult = {
          subtotal: this.subtotal,
          discountedAmount: discountedAmount.toFixed(2),
          total: (this.subtotal - discountedAmount).toFixed(2),
          discount: discount.toFixed(2) + '%',
          pending_values: paymentTotals - this.$stringToNumber(updatedValue),
          value_in_payment_note: updatedValue,
          // Se o pendingTotal for igual à diferença entre o total dos pagamentos e o valor líquido da venda após o desconto, o teste passou
          passed: paymentTotals - this.$stringToNumber(updatedValue) === 0,
        };

        results.push(testResult);
      }

      // // to txt file
      const text = JSON.stringify(results, null, 2);
      const blob = new Blob([text], { type: 'application/json' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'test_discount_global.json';
      a.click();
      // console.table(results);

      // gerar download em csv
      // let csv = results.map(row => Object.values(row).join(',')).join('\n');
      // const header = Object.keys(results[0]).join(',');
      // csv = header + '\n' + csv;
      // // add header
      // const blob = new Blob([csv], { type: 'text/csv' });
      // const url = window.URL.createObjectURL(blob);
      // const a = document.createElement('a');
      // a.href = url;
      // a.download = 'test_discount_global.csv';
      // a.click();
    },


    checkDocument () {
      this.note.client_id = this.client?.id || null;
      this.note.client_name = this.client?.name || null;
      this.note.client_address = this.client?.address || null;
      this.note.client_neighborhood = this.client?.neighborhood || null;
      this.note.client_zipcode = this.$toNumeric(this.client?.zipcode) || null;
      this.note.client_phone = this.$toNumeric(this.client?.phone) || null;

      this.note.lines = this.note.products.map((product) => {
        return {
          product_id: product.product_id,
          quantity: product.amount,
          units_of_measurement_id: product.units_of_measurement_id,
          name: product.name,
          description: product.description,
          unit_value: this.$stringToNumber(product.unit_value),
          total: this.$stringToNumber(product.total),
        }
      });

      const discount_percent = this.$stringToNumber(this.note.discount_global) || 0;
      this.note.discount_percent = discount_percent.toString();

      const discount_value = ((discount_percent / 100) * this.subtotal).toFixed(2);
      this.note.discount_value = discount_value;

      const total = parseFloat(this.subtotal - parseFloat(discount_value)).toFixed(2);
      this.note.total = total;
    },

    async submitDocument () {
      // delete this.note.products;
      this.checkDocument();

      if (this.pendingTotal > 0) {
        this.step = 4;
        
        return this.$swal({
          title: 'Saldo pendente',
          text: 'O valor pago é menor que o valor total da venda',
          icon: 'error',
          confirmButtonText: 'Revisar',
        })
      }

      const newSale = {
        ...this.note,
      }

      // removing products from the note (using lines instead)
      delete newSale.products;
      delete newSale.discount_global;

      const payments = newSale.payments.map((payment) => {
        return {
          ...payment,
          value: this.$stringToNumber(payment.value)
        }
      });

      const paymentsMap = payments.reduce((acc, item) => {
        const id = item.payment_id;

        if (item.value !== 0) { 
          acc[id] = (acc[id] || 0) + item.value; 
        } 
        
        return acc;
      }, {});

      const paymentsFiltered = Object.keys(paymentsMap).map(id => ({ payment_id: id, value: paymentsMap[id] }));
      newSale.payments = paymentsFiltered;
      
      const [response, error] = await this.$handleAsync(this.$axios.post('/sale', newSale));

      if (!error && response.data.success) {
        this.$notify({ title: 'Sucesso!', text: response.data.message, type: 'success' });

        const { data } = response.data;
        this.$emit('update-list', data.id)

        return
      }

      this.$notify({ title: "Erro!", text: "Algo deu errado ao salvar a venda.", type: "error" });
      
      try {
        const response = (await this.$axios.post('/sale', newSale));

        if (response.data.success) {
          this.$notify({ title: 'Sucesso!', text: response.data.message, type: 'success' });

          const { data } = response.data;
          
          this.$emit('update-list', data.id)
          this.$router.push(`/sale/${data.id}`);
        }
      } catch (error) {
        console.log(error);

        this.$notify({ title: "Erro!", text: "Algo deu errado ao salvar a venda.", type: "error" });
      }
    },

    async getCompanyInfo () {
      const [response, error] = await this.$handleAsync(this.$axios.get('company/1'));
      if (!error) this.company = response.data.data;
    },

    async getPaymentMethods () {
      const [response, error] = await this.$handleAsync(this.$axios.get('/payments'));
      if (!error) {
        const data = response.data.data || [];
        this.paymentList = data || [];
        this.addPaymentMethod({ payment_id: data[0].id, value: 0 });
      }
    },

    async getProducts() {
      // get the ref from the component
      const itemProducts = this.$refs.itemProducts;
      // call the method from the component
      await itemProducts.getProducts();
    },

    addProductToNote (product) {
      this.note.products.push(product);
    },

    removeProductFromNote (index) {
      this.note.products.splice(index, 1)
    },

    addPaymentMethod (payment) {
      this.note.payments.push(payment);
    },

    moneyFormat (value) {
      return value.toLocaleString('pt-br', { minimumFractionDigits: 2 });
    },

    closeProductModal () {
      this.productModal = false;
      this.productModalData = Object.assign({}, this.productDefault);
    },

    closeClientModal () {
      this.clientModal = false;
      this.clientModalData = Object.assign({}, this.clientDefault);
    },
  },
}
</script>

<style>
.text--success {
  color: green;
}

.text--error {
  color: red;
}
</style>

<style scoped>
.section {
  margin-block: 2rem;
  /* border: 1px solid #EAEAEA; */
  /* border-radius: 12px; */
}
</style>
