<template>
    <v-card class="pa-2">
        <v-card-title> <span class="text-h5"> {{ editedIndex == -1 ? 'Registrar nova despesa' : 'Editar despesa' }} </span> </v-card-title>

        <v-container>
            <v-form ref="form" :fast-fail="true" :lazy-validation="true">
                <v-row>
                <v-col cols="12" sm="6">
                    <v-text-field
                    label="Nome da despesa"
                    v-model="editedItem.name"
                    variant="solo-filled"
                    :rules="[rules.required]"
                    :disabled="submitting"
                    ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                    <v-text-field
                    v-model="editedItem.description"
                    label="Informações adicionais (opcional)"
                    variant="solo-filled"
                    placeholder="Ex: Pagamento aluguel adiantado de 3 meses"
                    :disabled="submitting"
                    ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                    <v-text-field
                    v-model.lazy="editedItem.cost"
                    label="Valor da despesa"
                    variant="solo-filled"
                    :rules="[rules.required, $minValueZero]"
                    v-money="{decimalCount: 2, precision: 2, allowNegative: false, prefix: 'R$ ', thousands: '.', decimal: ','}"
                    :disabled="submitting"
                    ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                    <v-text-field
                    type="date"
                    v-model="editedItem.date"
                    label="Data de vencimento"
                    variant="solo-filled"
                    placeholder="Ex: 2020-12-31"
                    :rules="[rules.required]"
                    :disabled="submitting"
                    ></v-text-field>
                </v-col>

                <v-col cols="12">
                    <v-select
                    :items="frequencies"
                    v-model="editedItem.frequency"
                    item-title="text"
                    item-value="value"
                    variant="solo-filled"
                    label="Escolha a frequência de pagamento"
                    :rules="[rules.required]"
                    :disabled="submitting"
                    ></v-select>
                </v-col>
                </v-row>
            </v-form>
        </v-container>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red-darken-1" variant="text" :disabled="submitting" @click="close">Cancelar</v-btn>
            <v-btn color="success" variant="text" :disabled="submitting" @click="save">{{ editedIndex == -1 ? 'Registrar' : 'Salvar' }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        name: 'cost-modal',

        props: {
            item: {
                type: Object,
                default: () => ({
                    name: '',
                    description: '',
                    cost: '',
                    date: '',
                    frequency: '',
                }),
            },
            submitting: {
                type: Boolean,
                default: false,
            },
            editedIndex : {
                type: Number,
                default: -1,
            },
        },

        inject: ['rules', 'frequencies'],

        computed: {
            value: {
                get() {
                    return this.modelValue;
                },
                set(val) {
                    this.$emit('input', val);
                },
            },
            editedItem: {
                get() {
                    return this.item;
                },
                set(val) {
                    this.$emit('update:editedItem', val);
                },
            },
        },

        methods: {
            close() {
                this.$emit('close');
            },

            async save() {
                const { valid } = await this.$refs.form.validate();
                if (!valid) {
                    return false;
                }

                this.$emit('save');
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>