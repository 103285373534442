<template>
  <div>
    <ListCRUD
      :headers="item.headers"
      :items="item.items"
      :loading="item.loading"
      :search="item.search"
      @action:edit="showModal"
      @action:delete="deleteItem"
      @action:refresh="fetchItems"
    >
      <template v-slot:header>
        <v-card class="pa-5 d-flex flex-column" color="transparent" rounded="12px" flat>
          <v-row class="mb-2" align="center">
            <v-col cols="12" lg="4">
              <h2> Lista de Produtos </h2>
            </v-col>

            <v-col cols="12" lg="4">
              <v-text-field
                :loading="item.loading"
                :disabled="item.loading"
                v-model="item.search"
                density="compact"
                variant="solo-filled"
                label="Pesquisar por nome, descrição"
                append-inner-icon="mdi-magnify"
                single-line
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" lg="3" class="ml-auto text-right">
              <v-btn @click="openModal" color="primary">
                Adicionar um produto
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </template>
    </ListCRUD>

    <v-dialog v-model="modal" persistent max-width="clamp(50%, 500px, 100%)">
      <ProductModal
        :units="units"
        :product="modalData"
        :editedIndex="editedIndex"
        @close="closeModal"
        @refresh="fetchItems"
      />
    </v-dialog>
  </div>
</template>

<script>
import ListCRUD from "../ListCRUD.vue";
import ProductModal from '@/components/Base/modal/productModal';
import Swal from 'sweetalert2';

export default {
  components: { ListCRUD, ProductModal },
  data() {
    return {
      units: [],
      
      item: {
        items: [],
        search: "",
        loading: true,
        headers: [
          { title: "ID", key: "id" },
          { title: "Nome", key: "name" },
          { title: "Descrição", key: "description" },
          { title: "Prç. Compra", key: "price_purchase" },
          { title: "Prç. Venda", key: "price_sale" },
          { title: "Margem %", key: "profit_margin" },
          { title: "Qtd", key: "quantity" },
          { title: "Ações", key: "actions", sortable: false },
        ],
      },

      modal: false,
      modalData: {},
      defaultData: {
        id: null,
        name: "",
        description: "",
        price_sale: 0,
        price_purchase: 0,
        profit_margin: 0,
        category_id: "",
        quantity: 0,
      },
      editedIndex: -1,
    };
  },

  async mounted() {
    await this.fetchItems();
    const { product_id } = this.$route.query || {};

    if (product_id) {
      const product = this.item.items.find((item) => item.id == product_id);

      if (product) {
        this.showModal(product);
      }
    }
  },

  methods: {
    closeModal() {
      this.modal = false;
      this.modalData = Object.assign({}, this.defaultData);
      this.editedIndex = -1;

      const { product_id } = this.$route.query || {};
      if (product_id) {
        this.$router.replace({ query: {} });
      }
    },

    showModal(item) {
      this.modalData = Object.assign({}, item);
      this.modal = true;
      this.editedIndex = this.item.items.indexOf(item);
    },

    openModal () {
      this.modalData = Object.assign({}, this.defaultData);
      this.modal = true;
    },

    async fetchItems () {
      this.item.loading = true;
      const [response, error] = await this.$handleAsync(this.$axios.get("/products"));
      if (!error && response.data.success) this.item.items = response.data.data;
      this.item.loading = false;
    },

    async deleteItem(item) {
      Swal.fire({
        title: 'Tem certeza?',
        text: "Você não poderá reverter isso!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, deletar!',
        cancelButtonText: 'Não, cancelar!',
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.deleteItemConfirmed(item);
        }
      })
    },

    deleteItemConfirmed (item) {
      this.$axios.delete(`/product/${item.id}`).then(() => {
        this.$notify({
          title: 'Sucesso!',
          text: 'Ação realizada com sucesso!',
          icon: 'success',
        })

        this.fetchItems();
      }).catch((e) => {
        console.log(e);
      })
    },
  },
};
</script>

<style lang="scss" scoped></style>
