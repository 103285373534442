<template>
  <v-card class="pa-5 d-flex flex-column">
    <v-row class="mb-4" align="center">
      <v-col cols="12" lg="4">
        <h2>Categorias de Produto</h2>
      </v-col>

      <v-col cols="12" lg="4">
        <v-text-field
          :loading="item.loading"
          :disabled="item.loading"
          v-model="item.search"
          density="compact"
          variant="solo-filled"
          label="Pesquisar por nome, descrição"
          append-inner-icon="mdi-magnify"
          single-line
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" lg="3" class="text-right">
        <v-btn color="primary" @click="showModal">  
          Nova Categoria
        </v-btn>
      </v-col>
    </v-row>

    <ListCRUD
      :headers="item.headers"
      :items="item.items"
      :loading="item.loading"
      :search="item.search"
      @action:edit="showModal"
      @action:delete="deleteItem"
      @action:refresh="fetchItems"
    />

    <v-dialog v-model="modal" max-width="500px">
      <v-card class="pa-2">
        <v-card-title class="px-0">
          <v-container>
            <span class="headline">{{ modalTitle }}</span>
          </v-container>
        </v-card-title>
        <v-form ref="form" class="px-4">
          <v-row>
            <v-col cols="12" lg="12" class="pb-0">
              <v-text-field
                variant="solo-filled"
                :rules="item.rules.name"
                v-model="modalData.name"
                label="Nome"
                required
                :disabled="saveLoading"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="12" class="pb-0">
              <v-textarea
                variant="solo-filled"
                :rules="[maxChar(255)]"
                v-model="modalData.description"
                label="Descrição"
                :disabled="saveLoading"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
        <v-card-actions class="px-4">
          <v-spacer></v-spacer>
          <v-btn
            color="darken-1"
            text
            @click="clearItem"
            :disabled="saveLoading"
          >
            Cancelar
          </v-btn>
          <v-btn color="green" @click="saveItem" :disabled="saveLoading">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2';
import ListCRUD from "../ListCRUD.vue";
export default {
  components: { ListCRUD },
  data() {
    return {
      item: {
        items: [],
        search: "",
        loading: true,
        headers: [
          { title: "ID", key: "id" },
          { title: "Nome", key: "name" },
          { title: "Descrição", key: "description" },
          { title: "Ações", key: "actions", sortable: false },
        ],

        // add rules for validation in the future
        rules: {
          name: [
            (v) => !!v || "Nome é obrigatório",
            (v) => v.length <= 255 || "Nome deve ter no máximo 255 caracteres",
          ],
        },
      },

      modal: false,
      saveLoading: false,
      modalTitle: "Nova Categoria",

      modalData: {
        id: null,
        name: "",
        description: "",
      },
    };
  },

  async mounted() {
    await this.fetchItems();
  },

  methods: {
    maxChar(maxAmount) { 
      return v => !v || v.length <= maxAmount || `Máximo de ${maxAmount} caracteres`;
    },

    clearItem(isClosing = true) {
      this.modalData = {
        id: null,
        name: "",
        description: "",
      };

      if (isClosing) {
        this.modal = false;
      }
    },
    showModal(item) {
      this.modal = true;

      if (item && !(item instanceof PointerEvent)) {
        this.modalTitle = "Editando Categoria";
        Object.assign(this.modalData, item);
      } else {
        this.modalTitle = "Nova Categoria";
        this.clearItem(false);
      }
    },
    async fetchItems () {
      this.item.loading = true;
      
      const [response, error] = await this.$handleAsync(this.$axios.get('/categories'));
      if (!error) this.item.items = response.data.data; 

      this.item.loading = false;
    },

    async saveItem () {
      if (!(await this.$refs.form.validate())) {
        return;
      }

      this.saveLoading = true;

      try {
        if (this.modalData.id) {
          await this.$axios.put(`/category/${this.modalData.id}`, this.modalData);
        } else {
          await this.$axios.post("/category", this.modalData);
        }

        this.clearItem();
        await this.fetchItems();

        this.$notify({
          title: 'Sucesso!',
          text: 'Categoria cadastrada com sucesso!',
          icon: 'success',
        })

      } catch (error) {
        console.log(error);

        this.$notify({
          title: 'Erro!',
          text: 'Erro ao cadastrar categoria!',
          icon: 'error',
        })
      }

      this.saveLoading = false;
    },

    async deleteItem(item) {
      // adicionar $swal para confirmar a exclusão

      Swal.fire({
        title: 'Tem certeza?',
        text: "Você não poderá reverter isso!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, deletar!',
        cancelButtonText: 'Não, cancelar!',
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$axios.delete(`/category/${item.id}`);
            await this.fetchItems();
          } catch (error) {
            console.log(error);
          }

          this.$notify({
            title: 'Sucesso!',
            text: 'Categoria deletada com sucesso!',
            icon: 'success',
          })
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped></style>
