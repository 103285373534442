import Swal from 'sweetalert2'
const Toast = Swal.mixin({
  position: 'top-end',
  backdrop: false, // update version v7.26.10 to https://github.com/sweetalert2/sweetalert2/issues/1191
  showConfirmButton: false,
  showCloseButton: true,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

export default {
  install: (app) => {
    app.config.globalProperties.$notify = (notification) => {
      notification.icon = notification.icon ?? 'success';
      notification.timer = notification.timer ?? 6000;
      notification.toast = notification.toast ?? true;
          
      if (notification.icon == 'empty') { delete notification.icon; }
      Toast.fire({ ...notification })
    }

    app.config.globalProperties.$swal = (notification) => {
      notification.icon = notification.icon ?? null; 
      notification.timer = notification.timer ?? 6000;
        
     Swal.fire({ ...notification }) 
    }
  }  
}