<template>
    <div>
        <template v-if="!loading">
            <DocumentsItemRender
                :note="sale"
                :saleStatus="saleStatus"
                :paymentTypes="paymentMethods"
                @update:note-state="updateState"
                @fetch:note="getSale"
                :submitting="submitting"
                v-if="showDetails"
            />

            <DocumentsStepperIndex v-else 
                @update-list="getSale"
            />
        </template>

        <template v-else>
            <v-container>
                <v-row>
                    <v-col cols="12" class="text-center">
                        <h1 class="display-1">Carregando...</h1>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </div>
</template>

<script>
    import { constants } from '@/mixins/constants';
    import DocumentsStepperIndex from "@/components/Documents/StepView.vue";
    import DocumentsItemRender from "@/components/Documents/ItemRender.vue";
    export default {
        mixins: [constants],
        name: 'page-sales-show',
        components: { DocumentsItemRender, DocumentsStepperIndex },

        data() {
            return {
                sale: {},
                showDetails: true,
                loading: true,
                submitting: false,

                paymentMethods: [],
            }
        },

        async mounted () {
            const { slug } = this.$route.params;
            if (!slug) return console.log('Slug não informado');

            await this.getSale(slug);
            await this.getPaymentMethods();
        },

        watch: {
            '$route.params.slug': {
                handler: async function (slug) {
                    if (!slug) return console.log('Slug não informado');
                    await this.getSale(slug);
                },
                immediate: true,
            }
        },

        methods: {
            async getSale (id) {
                if (id === 'new') {
                    this.showDetails = false;
                    this.loading = false;
                    return 
                }

                this.showDetails = false;
                this.submitting = true; 
                this.loading = true;

                const [response, error] = await this.$handleAsync(this.$axios.get(`/sale/${id}`));

                if (!error) {
                    this.sale = response.data.data;
                    this.showDetails = true;
                    this.submitting = false;
                    this.loading = false;
                } else {
                    this.showDetails = false;
                    this.submitting = false;
                    this.loading = false;

                    this.$notify({
                        icon: "error",
                        title: "Nota não encontrada",
                        text: "A nota que você está tentando acessar não existe ou não está disponível no momento",
                    })

                    this.$router.push('/sales');
                }
            },

            async updateState ({ note, state }) {
                this.submitting = true;

                const [response, error] = await this.$handleAsync(this.$axios.put(`/sale/${note.id}`, { state }));

                if (error) {
                    this.submitting = false;
                    return this.$notify({ icon: 'error', text: error.response.data.message });
                }

                this.submitting = false

                if (response.data.success) {
                    this.$notify({ type: 'success', text: 'Estado da nota atualizado com sucesso' })
                    await this.getSale(note.id);
                }
            },

            async getPaymentMethods () {
                const [response, error] = await this.$handleAsync(this.$axios.get('/payments'));

                if (error) return this.$notify({ icon: 'error', text: error.response.data.message });

                if (response.data.success) {
                    this.paymentMethods = response.data.data;
                }
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>