<template>
        <!-- <v-btn append-icon="mdi-printer" color="primary" class="mb-5" @click="doPrint">Imprimir</v-btn> -->
        <!-- TESTANDO O LAYOUT -->

        <v-row justify="center">
            <v-dialog v-model="waitingState" persistent width="auto" :absolute="true">
                <v-card class="pa-3" v-if="waitingState">
                    <v-card-text class="text-center">
                        <v-progress-circular indeterminate size="36" color="primary" class="mb-4"></v-progress-circular>
                        <h3 class="pa-2"> {{ currentStep }} </h3>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-col>
                <!-- back to list -->
                <v-btn
                    color="primary"
                    @click="this.$router.push('/sales');"
                    class="my-5"
                >
                    Voltar para a lista
                </v-btn>
            </v-col>

            <v-col cols="12">
                <v-card class="pa-5" id="print" v-if="note">
                    <v-chip :color="saleStatus[note.state].color" class="white--text my-4">{{ saleStatus[note.state].text }}</v-chip>

                    <v-row>
                        <v-col cols="12" lg="12">
                            <v-btn
                                v-for="(item, index) in note.allowedActions"
                                :key="`btn-${index}`"
                                class="white--text mr-4"
                                :color="item.color || [item.type == 'CO' ? 'success' : 'error']"
                                @click="updateState({ note, state: item.type })"
                                :disabled="submitting"
                                :loading="submitting"
                            >
                                {{ item.name }}
                            </v-btn>
                        </v-col>
                    </v-row>
                    
                    <v-card-title class="text-center text-h5 font-weight-bold my-4">
                        <span>NOTA DE BALCÃO Nº {{ note.number }}</span>
                    </v-card-title>
                    
                    <v-card-text>
                        <h2 class="">Informações do Cliente </h2>
                        <v-row class="mb-5 text-body-1 font-weight-light" align="center">
                            <v-col cols="12" lg="6">
                                <p>ID: {{ note.id }}</p>
                                <p>Número: {{ note.number }}</p>
                                <p>Data: {{ note.date }}</p>
                            </v-col>

                            <v-col cols="12" lg="6" class="text-right">
                                <p>Cliente: {{ note.client_name || 'N/A' }} </p>
                                <p>Endereço: {{ note.client_address || 'N/A' }} </p>
                                <p>Bairro: {{ note.client_neighborhood || 'N/A' }} </p>
                                <p>Telefone: {{ note.client_phone || 'N/A' }} </p>
                                <p>CEP: {{ note.client_zipcode || 'N/A' }} </p>
                            </v-col>
                        </v-row>

                        <h2 class="my-5"> Produtos </h2>

                        <v-data-table
                            :headers="[{ title: 'Produto', key: 'name' }, { title: 'Qtd', key: 'quantity' }, { title: 'Valor', key: 'total' }]"
                            :items="note.lines"                            
                        >
                            <template v-slot:[`item.total`]="{ item }">
                                <strong>{{ $money(item.raw.total) }} </strong>
                            </template>
                        
                        </v-data-table>

                        <v-divider></v-divider>

                        <h2 class="my-5"> Meios de Pagamento </h2>

                        <template v-for="(payment, index) in payments" :key="`payment-type-${index}`">
                            <div>
                                <div>
                                    <p class="text-body-2">Meio de Pagamento: <strong>{{ payment.name }}</strong></p>
                                    <p class="text-body-2">Valor: <strong>{{ $money(payment.amount) }}</strong></p>
                                </div>

                                <v-row class="pa-0" v-if="note.state == 'CO' && payment.code.includes('a_prazo') || payment.deletable && payments[index]">
                                    <v-col cols="12" lg="5">
                                        <v-autocomplete
                                            class="mt-5"
                                            :items="paymentTypes"
                                            v-model="payments[index].payment_id"
                                            item-title="name"
                                            item-value="id"
                                            label="Tipo de pagamento"
                                            variant="solo-filled"
                                            dense
                                        ></v-autocomplete>
                                    </v-col>
                                    
                                    <v-col cols="12" lg="5">
                                        <v-text-field
                                            class="mt-5"
                                            v-model.lazy="payments[index].value"
                                            label="Valor em R$"
                                            outlined
                                            dense
                                            hide-details=""
                                            variant="solo-filled"
                                            @keyup="updatePaymentValue({ index, value: payments[index].value })"
                                            v-money="{decimalCount: 2, precision: 2, allowNegative: false, prefix: 'R$ ', thousands: '.', decimal: ','}"
                                        />
                                    </v-col>

                                    <v-col cols="12" lg="2" class="d-flex align-center">
                                        <v-btn
                                            size="small"
                                            color="success"
                                            icon="mdi-plus"
                                            variant="plain"
                                            :disabled="this.pendingTotal <= 0"
                                            @click="addPaymentMethod({ payment_id: paymentTypes[0].id, value: 'R$ 0,00', ...paymentTypes[0] })"
                                            v-if="note.state == 'CO' && payment.code.includes('a_prazo') || payment.deletable"
                                        >  </v-btn> 

                                        <v-btn
                                            size="small"
                                            color="error"
                                            icon="mdi-delete"
                                            variant="plain"
                                            :disabled="payments.length === 1"
                                            @click="removePaymentMethod(index)"
                                            v-if="note.state == 'CO' && payment.deletable"
                                        > </v-btn>
                                    </v-col>
                                </v-row>

                                <v-divider class="my-4" v-if="index !== payments.length - 1"></v-divider>
                            </div>
                        </template>

                        <v-divider class="my-5"></v-divider>

                         <!-- / <strong> {{ note.discount_percent }}% </strong> -->
                        <p v-if="note.discount_value > 0">Desconto: <strong>{{ $money(note.discount_value) }}</strong></p> 
                        <p>Total: <strong>{{ $money(note.total) }}</strong></p>
                        
                    </v-card-text>

                    <v-card-actions v-if="note.state == 'CO' && payments.filter(item => item.code.includes('a_prazo')).length > 0">
                        <v-btn color="primary" @click="savePaymentType">Salvar Alterações</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
</template>

<script>
    import { constants } from '@/mixins/constants';
    import Swal from 'sweetalert2';
    export default {
        mixins: [constants],
        props: ['note', 'submitting', 'paymentTypes'],

        data() {
            return {
                payments: [],
                state: this.note.state,

                currentStep: '',
                waitingState: false,

                sending: false,
            }
        },
        
        computed: {
            stateItems () {
                return Object.keys(this.saleStatus).map(key => ({
                    text: this.saleStatus[key],
                    value: key,
                }));
            },

            pendingTotal () {
                const total = this.note.total;
                const pending = this.payments.reduce((acc, item) => acc + parseFloat(item.amount), 0);

                return total - pending;
            }
        },

        mounted () {
            if (this.note.payments_with_values.length > 0) {
                this.payments = this.note.payments_with_values.map((item) => ({
                    ...item,
                    payment_id: item.pivot.payment_id,
                    value: item.pivot.value,
                    amount: item.pivot.value,
                    deletable: false,
                }))
            }
        },

        methods: {
            async updateState ({ note, state }) {
                const allowedStates = ['create_pdf', '2_via', 'replace_pdf', 'tmp-file', 'send_email'];
                if (!allowedStates.includes(state)) {
                    this.$emit('update:note-state', { note, state });

                    if (state === 'CO') {
                        this.setLoadingState(true, 'Salvando documento, por favor aguarde...');
                        await this.handleCOState();
                    }
                }

                if (state === 'create_pdf' || state === 'replace_pdf') {
                    await this.handleCOState();
                }

                if (state === '2_via') {
                    await this.downloadDoc();
                }

                if (state === 'tmp-file') {
                    await this.handleCOState(true);
                }

                if (state === 'send_email') {
                    console.log('send_email', this.note);
                    const { value: email } = await Swal.fire({
                        title: "Enviar por email",
                        input: "email",
                        inputLabel: "Para qual email deseja enviar o documento?",
                        inputPlaceholder: "Digite o email para enviar o documento",
                        inputValue: this.note.client.email,
                        showCancelButton: true,
                        confirmButtonText: "Enviar",
                        cancelButtonText: "Cancelar",

                        inputValidator: (value) => {
                            if (!value) {
                                return "Por favor, digite um email válido";
                            }
                        },
                    });

                    if (email) {
                        this.setLoadingState(true, 'Enviando email, por favor aguarde...');
                        this.handleAsyncOperation(this.$axios.post(`/sale/send-document/${this.note.id}`, { to: email })).then(([response, error]) => {
                            if (error) {
                                this.$notify({ icon: 'error', text: error.response.data.message });
                            } else if (response) {
                                this.$notify({ icon: 'success', text: 'Email enviado com sucesso!' });
                            }

                            this.setLoadingState();
                        });
                    }
                }
            },

            savePaymentType () {
                if (this.sending) return;

                if (this.pendingTotal !== 0) {
                    return this.$swal({
                        title: 'Saldo pendente',
                        text: `${this.pendingTotal > 0 ? 'Faltando' : 'Sobrando'} ${this.$money(this.pendingTotal)} ao acertar o pagamento.`,
                        icon: 'error',
                        confirmButtonText: 'Revisar',
                    })
                }

                const payments = this.payments.map(item => ({
                    payment_id: item.payment_id,
                    value: parseFloat(item.amount)
                }));

                Swal.fire({
                    title: 'Confirmação',
                    text: 'Deseja realmente salvar as alterações?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não',
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        const paymentsMap = payments.reduce((acc, item) => {
                            const id = item.payment_id;
                            if (item.value !== 0) { 
                                acc[id] = (acc[id] || 0) + item.value; 
                            } 

                            return acc;
                        }, {});

                        const paymentsFiltered = Object.keys(paymentsMap).map(id => ({ payment_id: id, value: paymentsMap[id] }));              
                        const [response, error] = await this.handleAsyncOperation(this.$axios.put(`/sale/${this.note.id}`, { state: this.note.state, payments: paymentsFiltered }));

                        if (error) {
                            this.sending = false;

                            const errors = Object.values(error.response.data.errors)
                            .filter(value => Array.isArray(value) || typeof value === 'string')
                            .reduce((acc, value) => acc + (Array.isArray(value) ? value.join('\n') : value) + '\n', '');

                            return this.$notify({ icon: 'error', html: errors });	
                        }

                        this.sending = false;

                        if (response.data.success) {
                            this.$notify({ icon: 'success', text: 'Metodos de pagamento atualizados com sucesso' })
                            this.$emit('fetch:note', this.note.id);
                        }
                    }
                })
            },

            addPaymentMethod (payment) { 
                this.payments.push({ ...payment, amount: 0, value: 'R$ 0,00', deletable: true });
            },

            removePaymentMethod (index) {
                if (this.payments[index].deletable) {
                    this.payments.splice(index, 1);
                }
            },

            updatePaymentValue ({ index, value }) { 
                if (!value) return this.payments[index].amount = 0;
                this.payments[index].amount = this.$stringToNumber(value);
            },

            async handleCOState(isPreview = false) {
                try {
                    const generatePDFResult = await this.generatePDF(isPreview);
                    const downloadDocResult = generatePDFResult && await this.downloadDoc(isPreview);

                    if (downloadDocResult) {
                        this.setLoadingState();
                    }
                } catch (error) {
                    this.$swal({
                        title: 'Algo deu errado',
                        text: error,
                        icon: 'error',
                        confirmButtonText: 'Ok',
                    })
                    
                    this.setLoadingState();
                }
            },

            generatePDF(preview = false) {
                return new Promise((resolve, reject) => {
                    const message = preview ? 'Gerando pré-visualização do documento, por favor aguarde...' : 'Criando documento, por favor aguarde...';
                    this.setLoadingState(true, message);

                    this.handleAsyncOperation(this.$axios.post(`/document/sale/pdf/a4/${this.note.id}`, { tmp: preview }))
                    .then(([response, error]) => {
                        if (error && !response) {
                            this.setLoadingState();
                            this.$notify({ icon: 'error', text: error.response.data.message });

                            reject("Falha ao gerar documento, tente novamente.");
                        } else {
                            resolve(true);
                            this.setLoadingState();
                        }
                    })
                    .catch((err) => {
                        this.setLoadingState();
                        reject(err);
                    });
                });
            },

            downloadDoc(preview = false) {
                return new Promise((resolve, reject) => {
                    const message = preview ? 'Gerando pré-visualização do documento, por favor aguarde...' : 'Efetuando o download do seu arquivo, por favor aguarde...';
                    this.setLoadingState(true, message);

                    // add a delay to show the loading state to the user 
                    setTimeout(() => {
                        this.handleAsyncOperation(this.$axios.get(`/document/sale/pdf/a4/${this.note.id}`, { responseType: 'arraybuffer', tmp: preview }))
                        .then(([response, error]) => {
                            if (error) {
                                this.setLoadingState();
                                this.$notify({ icon: 'error', text: error.response.data.error.message });
                                reject("Falha ao efetuar o download do documento, tente novamente.");
                            } else if (response && response.data) {
                                const blob = new Blob([response.data], { type: 'application/pdf' });
                                const link = document.createElement('a');

                                link.href = window.URL.createObjectURL(blob);
                                link.download = `venda-${this.note.number}.pdf`;
                                link.click();

                                this.setLoadingState();
                                resolve(true);
                            }
                        })
                        .catch((err) => {
                            this.setLoadingState();
                            reject(err);
                        });
                    }, 1500);
                });
            },

            setLoadingState (loading = false, message = '') {
                this.currentStep = message || '';
                this.waitingState = loading || false;
            },
        },
    }
</script>

<style lang="scss" scoped>
</style>