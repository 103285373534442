<template>
  <v-row>
    <v-col cols="12" lg="6" class="fill-height">
      <ProductsAlert />
    </v-col>

    <v-col cols="12" lg="6" class="fill-height">
      <product-divergences />
    </v-col>
  </v-row>
</template>

<script>
import ProductsAlert from '@/components/Base/panel/ProductsAlerts.vue';
import ProductDivergences from '@/components/Base/panel/ProductDivergences.vue';

export default {
  name: 'page-home',
  components: { ProductsAlert, ProductDivergences, },
}
</script>

<style lang="scss" scoped>

</style>