<template>
    <div>
        page
    </div>
</template>
  
<script>
import { mapActions } from 'vuex';
export default {
    methods: {
        ...mapActions(['doLogout']),
    },
    mounted() {
        this.doLogout();
    }
};
</script>