<template>
    <v-card class="pa-3">
        <v-card-title> Alerta de Estoque </v-card-title>

        <v-card-text>
            <div class="d-flex align-center text-amber">
                <span>{{ filteredProducts.length > 0 ? 'Produtos em alerta' : 'Tudo certo por aqui!' }}</span>
                <strong v-if="filteredProducts.length > 0"> ({{ filteredProducts.length }}) </strong>
            </div>
        </v-card-text>

        <div class="v-max">
            <v-alert variant="tonal" v-for="(product, index) in alerts" :key="index" class="mb-2" :icon="product.status.includes('Crítico') ? 'mdi-alert-octagon' : 'mdi-alert'" :type="product.status.includes('Crítico') ? 'error' : 'warning'">
                <v-row align="center">
                    <v-col cols="12" md="5">
                        <v-tooltip :text="product.product_name" location="top">
                            <template v-slot:activator="{ props }">
                                <h4 v-bind="props"> {{ maxChars(product.product_name, 24) }} </h4>
                            </template>
                        </v-tooltip>

                        <router-link :to="`/products?product_id=${product.id}`" class="text-white text-decoration-none"> Ver detalhes </router-link>
                    </v-col>

                    <v-col cols="12" md="5" align-self="center">
                        <div class="text--primary font-weight-black d-flex align-center"> 
                            <v-tooltip text="Quantidade Atual" location="top">
                                <template v-slot:activator="{ props }">
                                    <div v-bind="props" title="Quantidade Atual" class="d-flex align-center"> 
                                        <v-icon size="16" start>mdi-warehouse</v-icon>
                                        <span> {{ product.quantity }} </span>
                                    </div>
                                </template>
                            </v-tooltip>

                            <span class="mx-2"> / </span>

                            <v-tooltip text="Quantidade crítica" location="top">
                                <template v-slot:activator="{ props }">
                                    <div v-bind="props" title="Quantidade crítica" class="d-flex align-center"> 
                                        <v-icon size="16" start>mdi-alert</v-icon>
                                        <span> {{ product.alert_quantity }} </span>
                                    </div>
                                </template>
                            </v-tooltip>
                        </div>
                                            
                        <v-progress-linear
                            :model-value="product.quantity"
                            :max="product.alert_quantity"
                            color="grey darken-5"
                            height="8"
                            class="my-2"
                        ></v-progress-linear>
                    </v-col>

                    <v-col cols="12" md="2">
                        <div class="font-weight-bold text-white"> Status </div>
                        <strong class="text--primary"> {{ product.status }} </strong>
                    </v-col>
                </v-row>
            </v-alert>
        </div>
    </v-card>
</template>

<script>
import { constants } from '@/mixins/constants'; 
import { mapState } from 'vuex';
    export default { 
        mixins: [constants],
        mounted () {
            this.fetchProducts();
        },

        computed: {
            ...mapState(['productAlerts']),
            filteredProducts () {
                return this.alerts.filter(product => product.status.includes('Crítico', 'Alerta'));
            },

            alerts () {
                return (this.productAlerts || []).sort((a, b) => {
                    return b.diff_percent - a.diff_percent;
                });
            }
        },

        methods: {
            async fetchProducts () {
                this.$store.dispatch('getAlerts')
            },

            maxChars(text, size) {
                if (!text) return '';
                if (text.length > size) {
                    return text.substring(0, size) + '...';
                }
                return text;
            }
        }
    }
</script>

<style scoped>
.v-max {
    --card-size: calc(80px + 2rem);
    height: 100%;
    max-height: calc(var(--card-size) * 6);
    overflow: hidden;
    overflow-y: auto;
}
</style>